import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VSimpleTable, {
    staticStyle: { width: "100%" },
    attrs: { "fixed-header": "", height: "100%;" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-left" }, [
                  _vm._v("\n                Reason\n            "),
                ]),
                _c("th", { staticClass: "text-left" }, [
                  _vm._v("\n                Timestamp\n            "),
                ]),
                _c("th", { staticClass: "text-left" }, [
                  _vm._v("\n                Message\n            "),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.events, function (item) {
                return _c("tr", { key: item.involvedObject.uid }, [
                  _c("td", [_vm._v(_vm._s(item.reason))]),
                  _c("td", [_vm._v(_vm._s(item.lastTimestamp))]),
                  _c("td", [_vm._v(_vm._s(item.message))]),
                ])
              }),
              0
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }