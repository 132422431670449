import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.condition
    ? _c(
        VDialog,
        {
          attrs: { "max-width": "370", persistent: "" },
          on: {
            "~click": function ($event) {
              return _vm.close()
            },
          },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.condition.title)),
              ]),
              _c(
                VCardText,
                [
                  _vm.condition.type != "project"
                    ? _c("div", [
                        _vm.condition.connectedAssociatedProject
                          ? _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.openProjectTab()
                                  },
                                },
                              },
                              [
                                _c(VIcon, { attrs: { small: "" } }, [
                                  _vm._v("mdi-link-variant"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.condition.associatedProject) +
                                    "\n                    "
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                style: _vm.openAssociatedProject
                                  ? "height: 45px"
                                  : "",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.openAssociatedProject =
                                          !_vm.openAssociatedProject
                                      },
                                    },
                                  },
                                  [
                                    _c(VIcon, { attrs: { small: "" } }, [
                                      _vm._v("mdi-pencil"),
                                    ]),
                                    _vm._v(" Connect Project"),
                                  ],
                                  1
                                ),
                                _vm.openAssociatedProject
                                  ? _c(VTextField, {
                                      staticClass: "custom-hint-color",
                                      staticStyle: {
                                        "font-weight": "900",
                                        "margin-top": "-20px",
                                      },
                                      attrs: {
                                        hint: "Once connected, changes cannot be made.",
                                        "error-messages":
                                          _vm.condition.error &&
                                          _vm.condition.error[
                                            "associatedProject"
                                          ],
                                      },
                                      model: {
                                        value: _vm.condition.associatedProject,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.condition,
                                            "associatedProject",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "condition.associatedProject",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                      ])
                    : _vm._e(),
                  _vm.condition.action == "fork" && _vm.condition.isForkModel
                    ? _c(
                        "div",
                        { staticStyle: { "font-size": "15px" } },
                        [
                          _c("span", {
                            staticClass: "mdi mdi-alert-outline",
                            staticStyle: {
                              color: "#FFA726",
                              "font-weight": "700",
                              "font-size": "20px",
                            },
                          }),
                          _vm._v(" You've already FORKED this model."),
                          _c("br"),
                          _vm._v(
                            "\n                    Click the arrow to access the model\n                    "
                          ),
                          _vm.condition.action == "fork" &&
                          _vm.condition.isForkModel
                            ? _c(
                                VBtn,
                                {
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changedModel()
                                    },
                                  },
                                },
                                [
                                  _c(VIcon, [
                                    _vm._v(
                                      "\n                            mdi-arrow-right-bold-circle-outline\n                        "
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("br"),
                  _vm.condition.action != "backup"
                    ? _c(VTextField, {
                        staticStyle: { "font-weight": "900" },
                        attrs: {
                          label: "* Definition ID(Unique ID)",
                          "error-messages":
                            _vm.condition.error &&
                            _vm.condition.error["projectId"],
                        },
                        model: {
                          value: _vm.condition.projectId,
                          callback: function ($$v) {
                            _vm.$set(_vm.condition, "projectId", $$v)
                          },
                          expression: "condition.projectId",
                        },
                      })
                    : _vm._e(),
                  _vm.condition.type == "project"
                    ? _c(VTextField, {
                        attrs: { disabled: false, label: "Project Name" },
                        model: {
                          value: _vm.condition.projectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.condition, "projectName", $$v)
                          },
                          expression: "condition.projectName",
                        },
                      })
                    : _vm._e(),
                  _vm.condition.action == "fork"
                    ? _c(VTextField, {
                        attrs: { label: "* Project Name(Edit)" },
                        model: {
                          value: _vm.condition.editProjectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.condition, "editProjectName", $$v)
                          },
                          expression: "condition.editProjectName",
                        },
                      })
                    : _vm._e(),
                  _vm.condition.action != "fork" &&
                  _vm.condition.type != "project"
                    ? _c(VTextField, {
                        style:
                          _vm.condition.action == "save"
                            ? ""
                            : " font-weight: 900;",
                        attrs: {
                          label: "Version Name",
                          "error-messages":
                            _vm.condition.error &&
                            _vm.condition.error["version"],
                        },
                        model: {
                          value: _vm.condition.version,
                          callback: function ($$v) {
                            _vm.$set(_vm.condition, "version", $$v)
                          },
                          expression: "condition.version",
                        },
                      })
                    : _vm._e(),
                  _vm.condition.isReplay
                    ? _c(VCheckbox, {
                        staticStyle: { "margin-top": "-10px" },
                        attrs: { dense: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n                        Attach SCM version\n                    "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4264949911
                        ),
                        model: {
                          value: _vm.condition.usedSCM,
                          callback: function ($$v) {
                            _vm.$set(_vm.condition, "usedSCM", $$v)
                          },
                          expression: "condition.usedSCM",
                        },
                      })
                    : _vm._e(),
                  _vm.condition.usedSCM
                    ? _c(VTextField, {
                        staticStyle: { width: "97%", "margin-left": "3%" },
                        attrs: {
                          dense: "",
                          label: "Url",
                          "error-messages":
                            _vm.condition.error &&
                            _vm.condition.error["scmUrl"],
                        },
                        model: {
                          value: _vm.condition.scmUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.condition, "scmUrl", $$v)
                          },
                          expression: "condition.scmUrl",
                        },
                      })
                    : _vm._e(),
                  _vm.condition.usedSCM
                    ? _c(VTextField, {
                        staticStyle: {
                          width: "97%",
                          "margin-left": "3%",
                          "margin-top": "12px",
                        },
                        attrs: { dense: "", label: "Tag Name" },
                        model: {
                          value: _vm.condition.scmVersion,
                          callback: function ($$v) {
                            _vm.$set(_vm.condition, "scmVersion", $$v)
                          },
                          expression: "condition.scmVersion",
                        },
                      })
                    : _vm._e(),
                  _c(VTextarea, {
                    attrs: {
                      outline: "",
                      name: "input-7-4",
                      label: "Comment",
                      counter: 255,
                      rows: "4",
                    },
                    model: {
                      value: _vm.condition.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.condition, "comment", $$v)
                      },
                      expression: "condition.comment",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                  _vm.condition.loading
                    ? _c(VProgressCircular, {
                        attrs: { indeterminate: "", color: "primary" },
                      })
                    : _c(
                        VBtn,
                        {
                          staticClass: "cp-es-dialog-save",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submit()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.submitText))]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }