import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ideLoading
    ? _c("div", [_c("router-view")], 1)
    : _c(
        VApp,
        {
          staticStyle: { overflow: "hidden" },
          attrs: { id: "inspire" },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              _vm.overlay = false
            },
          },
        },
        [
          _c(
            VAppBar,
            {
              staticStyle: {
                left: "0px",
                "background-color": "transparent",
                "z-index": "1",
              },
              attrs: {
                "clipped-left": _vm.$vuetify.breakpoint.lgAndUp,
                elevation: "0",
                app: "",
                fixed: "",
              },
            },
            [
              _c(
                VToolbarTitle,
                { staticClass: "ml-0 pl-3", staticStyle: { width: "360px" } },
                [
                  _c(VLayout, [
                    _vm.inSideRounge
                      ? _c(
                          "div",
                          [
                            _c(
                              VIcon,
                              {
                                staticStyle: {
                                  "font-size": "30px",
                                  "margin-top": "20px",
                                  "margin-right": "10px",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.moveToCourses()
                                  },
                                },
                              },
                              [_vm._v("mdi-arrow-left")]
                            ),
                            _vm.isMobile
                              ? _c(
                                  VIcon,
                                  {
                                    staticStyle: {
                                      "font-size": "30px",
                                      "margin-top": "20px",
                                      "margin-right": "10px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.naviControll()
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-format-list-bulleted")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", [_c("LogoView")], 1),
                  ]),
                ],
                1
              ),
              _c(VSpacer),
              _vm.labInfo && _vm.labInfo.tool == "url" && _vm.inSideElectron
                ? _c(
                    "div",
                    [
                      _c(
                        VIcon,
                        {
                          staticStyle: { "margin-right": "3px" },
                          on: {
                            click: function ($event) {
                              return _vm.goBack()
                            },
                          },
                        },
                        [_vm._v("mdi-arrow-left")]
                      ),
                      _c(
                        VIcon,
                        {
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function ($event) {
                              return _vm.goForward()
                            },
                          },
                        },
                        [_vm._v("mdi-arrow-right")]
                      ),
                      _c(
                        VIcon,
                        {
                          staticStyle: {
                            "margin-left": "5px",
                            "margin-right": "5px",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.reLoadPage()
                            },
                            contextmenu: function ($event) {
                              $event.preventDefault()
                              return _vm.contextOpen($event)
                            },
                          },
                        },
                        [_vm._v("\n                mdi-reload\n            ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.labInfo && _vm.labInfo.tool == "url" && _vm.inSideElectron
                ? _c(VTextField, {
                    staticStyle: { "margin-top": "25px", width: "400px" },
                    attrs: { filled: "", rounded: "", dense: "" },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.sendUrl(_vm.urlText)
                      },
                    },
                    model: {
                      value: _vm.urlText,
                      callback: function ($$v) {
                        _vm.urlText = $$v
                      },
                      expression: "urlText",
                    },
                  })
                : _vm._e(),
              _vm.labInfo && _vm.labInfo.tool == "url" && _vm.inSideElectron
                ? _c(
                    "div",
                    [
                      _c(
                        VIcon,
                        {
                          staticStyle: { "margin-left": "5px" },
                          on: {
                            click: function ($event) {
                              return _vm.screenPlus()
                            },
                          },
                        },
                        [_vm._v("mdi-plus")]
                      ),
                      _c(
                        VIcon,
                        {
                          staticStyle: { "margin-left": "5px" },
                          on: {
                            click: function ($event) {
                              return _vm.screenMinus()
                            },
                          },
                        },
                        [_vm._v("mdi-minus")]
                      ),
                    ],
                    1
                  )
                : _vm.inCourse && !_vm.showNewButton
                ? _c(
                    VRow,
                    {
                      staticStyle: { width: "100%", height: "57px" },
                      attrs: { dense: "" },
                    },
                    [
                      _c(VTextField, {
                        staticClass: "learn-main-search",
                        attrs: {
                          outlined: "",
                          autofocus: "",
                          label: "Search Class",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { width: "15%" } },
                [
                  _vm.showParticipantIcons
                    ? _c("participant-icons", {
                        staticClass: "clear-icon",
                        attrs: { lists: _vm.participantLists },
                        on: { openParticipantPanel: _vm.openParticipantPanel },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.inCourse && !_vm.showNewButton
                ? _c(
                    VBtn,
                    _vm._g(
                      _vm._b(
                        {
                          staticStyle: {
                            "font-weight": "700",
                            margin: "27px 110px 0px 0px",
                            padding: "0px 5px 0px 5px !important",
                          },
                          attrs: { text: "", elevation: "0" },
                          on: {
                            click: function ($event) {
                              return _vm.addNewClass()
                            },
                          },
                        },
                        "v-btn",
                        _vm.attrs,
                        false
                      ),
                      _vm.on
                    ),
                    [
                      _c(
                        VIcon,
                        {
                          staticStyle: { margin: "-3px 1px 0px 0px" },
                          attrs: { size: 26 },
                        },
                        [_vm._v("mdi-file-plus")]
                      ),
                      _vm._v("\n            신규 강의 생성\n        "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showNewButton
                ? _c(
                    VDialog,
                    {
                      attrs: { "max-width": "90%" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  VBtn,
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "making-main-nav-modeling-is-mobile",
                                        class: _vm.isForeign
                                          ? "isForeign-create-main-nav-is-mobile"
                                          : "isForeign-not-create-main-nav-is-mobile",
                                        style: _vm.isForeign
                                          ? {
                                              "margin-right": "300px",
                                              "margin-top": "4px",
                                            }
                                          : "margin-right: 245px",
                                        attrs: { text: "" },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      VIcon,
                                      {
                                        staticStyle: {
                                          margin: "0px 3px 0px 0px",
                                        },
                                        attrs: { size: 26 },
                                      },
                                      [_vm._v("mdi-file-plus")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        style: _vm.isForeign
                                          ? { marginTop: "-2px" }
                                          : { marginTop: "2px" },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("making.title")))]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2760754521
                      ),
                      model: {
                        value: _vm.makingDialog,
                        callback: function ($$v) {
                          _vm.makingDialog = $$v
                        },
                        expression: "makingDialog",
                      },
                    },
                    [
                      _c(
                        VCard,
                        {
                          staticStyle: {
                            padding: "20px",
                            height: "85vh",
                            overflow: "auto",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "24px",
                                "font-weight": "700",
                                "text-align": "center",
                                margin: "5px 0px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("making.title")))]
                          ),
                          _c("div", { staticClass: "making-sub-title" }, [
                            _vm._v(_vm._s(_vm.$t("making.design"))),
                          ]),
                          _c(
                            VRow,
                            { staticStyle: { margin: "0px" } },
                            _vm._l(_vm.design, function (item, index) {
                              return _c(
                                VCol,
                                {
                                  key: index,
                                  staticClass: "making-col",
                                  attrs: {
                                    lg: "4",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      staticStyle: {
                                        padding: "15px",
                                        height: "100%",
                                        position: "relative",
                                      },
                                      attrs: { outlined: "" },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                                "font-size": "18px",
                                                color: "black",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(_vm.$t(item.title)) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(VSpacer),
                                          item.tagStatus === "Stable"
                                            ? _c(
                                                VChip,
                                                {
                                                  staticClass: "gs-stable-chip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(item.tagStatus) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    color: "orange",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.tagStatus))]
                                              ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VRow,
                                        [
                                          _c(
                                            VCol,
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(VImg, {
                                                staticClass:
                                                  "cp-create-model-img",
                                                staticStyle: {
                                                  height: "150px",
                                                  margin: "10px 0px",
                                                  cursor: "pointer",
                                                },
                                                attrs: { src: item.image },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.moveToModel(
                                                      item.type
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            color: "#757575",
                                            margin: "10px 0px 30px 0px",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t(item.subtitle)))]
                                      ),
                                      _c(
                                        VCardActions,
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0px",
                                            bottom: "0px",
                                          },
                                        },
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goTutorials(
                                                    item.type
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.tutorial-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goVideo(item.type)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.video-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                color: "#1E88E5",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.moveToModel(
                                                    item.type
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.create-btn")
                                                ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c("div", { staticClass: "making-sub-title" }, [
                            _vm._v(_vm._s(_vm.$t("making.planning"))),
                          ]),
                          _c(
                            VRow,
                            { staticStyle: { margin: "0px" } },
                            _vm._l(_vm.planning, function (item, index) {
                              return _c(
                                VCol,
                                {
                                  key: index,
                                  staticClass: "making-col",
                                  attrs: {
                                    lg: "3",
                                    md: "3",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      staticStyle: {
                                        padding: "15px",
                                        height: "100%",
                                        position: "relative",
                                      },
                                      attrs: { outlined: "" },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                                "font-size": "18px",
                                                color: "black",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(_vm.$t(item.title)) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(VSpacer),
                                          item.tagStatus === "Stable"
                                            ? _c(
                                                VChip,
                                                {
                                                  staticClass: "gs-stable-chip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(item.tagStatus) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    color: "orange",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.tagStatus))]
                                              ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VRow,
                                        [
                                          _c(
                                            VCol,
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(VImg, {
                                                staticStyle: {
                                                  height: "150px",
                                                  margin: "10px 0px",
                                                  cursor: "pointer",
                                                },
                                                attrs: { src: item.image },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.moveToModel(
                                                      item.type
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            color: "#757575",
                                            margin: "10px 0px 30px 0px",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t(item.subtitle)))]
                                      ),
                                      _c(
                                        VCardActions,
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0px",
                                            bottom: "0px",
                                          },
                                        },
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goTutorials(
                                                    item.type
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.tutorial-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goVideo(item.type)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.video-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                color: "#1E88E5",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.moveToModel(
                                                    item.type
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.create-btn")
                                                ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c("div", { staticClass: "making-sub-title" }, [
                            _vm._v(_vm._s(_vm.$t("making.migration"))),
                          ]),
                          _c(
                            VRow,
                            { staticStyle: { margin: "0px" } },
                            _vm._l(_vm.migration, function (item, index) {
                              return _c(
                                VCol,
                                {
                                  key: index,
                                  staticClass: "making-col",
                                  attrs: {
                                    lg: "4",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      staticStyle: {
                                        padding: "15px",
                                        height: "100%",
                                        position: "relative",
                                      },
                                      attrs: { outlined: "" },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                                "font-size": "18px",
                                                color: "black",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(_vm.$t(item.title)) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(VSpacer),
                                          item.tagStatus === "Stable"
                                            ? _c(
                                                VChip,
                                                {
                                                  staticClass: "gs-stable-chip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(item.tagStatus) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    color: "orange",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.tagStatus))]
                                              ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VRow,
                                        [
                                          _c(
                                            VCol,
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(VImg, {
                                                staticStyle: {
                                                  height: "150px",
                                                  margin: "10px 0px",
                                                  cursor: "pointer",
                                                },
                                                attrs: { src: item.image },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.moveToModel(
                                                      item.type
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            color: "#757575",
                                            margin: "10px 0px 30px 0px",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t(item.subtitle)))]
                                      ),
                                      _c(
                                        VCardActions,
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0px",
                                            bottom: "0px",
                                          },
                                        },
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goTutorials(
                                                    item.type
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.tutorial-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goVideo(item.type)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.video-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                color: "#1E88E5",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.moveToModel(
                                                    item.type
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.create-btn")
                                                ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c("div", { staticClass: "making-sub-title" }, [
                            _vm._v(_vm._s(_vm.$t("making.operation"))),
                          ]),
                          _c(
                            VRow,
                            { staticStyle: { margin: "0px" } },
                            _vm._l(_vm.development, function (item, index) {
                              return _c(
                                VCol,
                                {
                                  key: index,
                                  staticClass: "making-col",
                                  attrs: {
                                    lg: "4",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      staticStyle: {
                                        padding: "15px",
                                        height: "100%",
                                        position: "relative",
                                      },
                                      attrs: { outlined: "" },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                                "font-size": "18px",
                                                color: "black",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(_vm.$t(item.title)) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(VSpacer),
                                          item.tagStatus === "Stable"
                                            ? _c(
                                                VChip,
                                                {
                                                  staticClass: "gs-stable-chip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(item.tagStatus) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    color: "orange",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.tagStatus))]
                                              ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VRow,
                                        [
                                          _c(
                                            VCol,
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(VImg, {
                                                staticStyle: {
                                                  height: "150px",
                                                  margin: "10px 0px",
                                                  cursor: "pointer",
                                                },
                                                attrs: { src: item.image },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.moveToModel(
                                                      item.type
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            color: "#757575",
                                            margin: "10px 0px 30px 0px",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t(item.subtitle)))]
                                      ),
                                      _c(
                                        VCardActions,
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0px",
                                            bottom: "0px",
                                          },
                                        },
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goTutorials(
                                                    item.type
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.tutorial-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goVideo(item.type)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.video-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                color: "#1E88E5",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.moveToModel(
                                                    item.type
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.create-btn")
                                                ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c("div", { staticClass: "making-sub-title" }, [
                            _vm._v(_vm._s(_vm.$t("making.project"))),
                          ]),
                          _c(
                            VRow,
                            { staticStyle: { margin: "0px" } },
                            _vm._l(_vm.makingProject, function (item, index) {
                              return _c(
                                VCol,
                                {
                                  key: index,
                                  staticClass: "making-col",
                                  attrs: {
                                    lg: "4",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      staticStyle: {
                                        padding: "15px",
                                        height: "100%",
                                        position: "relative",
                                      },
                                      attrs: { outlined: "" },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                                "font-size": "18px",
                                                color: "black",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(_vm.$t(item.title)) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(VSpacer),
                                          item.tagStatus === "Stable"
                                            ? _c(
                                                VChip,
                                                {
                                                  staticClass: "gs-stable-chip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(item.tagStatus) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    color: "orange",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.tagStatus))]
                                              ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VRow,
                                        [
                                          _c(
                                            VCol,
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(VImg, {
                                                staticStyle: {
                                                  height: "150px",
                                                  margin: "10px 0px",
                                                  cursor: "pointer",
                                                },
                                                attrs: { src: item.image },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.moveToModel(
                                                      item.type
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            color: "#757575",
                                            margin: "10px 0px 30px 0px",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t(item.subtitle)))]
                                      ),
                                      _c(
                                        VCardActions,
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0px",
                                            bottom: "0px",
                                          },
                                        },
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goTutorials(
                                                    item.type
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.tutorial-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goVideo(item.type)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.video-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                color: "#1E88E5",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.moveToModel(
                                                    item.type
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.create-btn")
                                                ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VBtn,
                {
                  staticClass: "question-btn",
                  staticStyle: { "text-transform": "none" },
                  attrs: { text: "", elevation: "0" },
                  on: { click: _vm.navigateToSlack },
                },
                [
                  _c(VImg, {
                    staticStyle: {
                      width: "24px",
                      height: "24px",
                      "margin-right": "5px",
                    },
                    attrs: {
                      src: "/static/image/main/question.svg",
                      contain: "",
                    },
                  }),
                  _c("div", { attrs: { "font-size": "14" } }, [
                    _vm._v(_vm._s(_vm.$t("inquiry.title"))),
                  ]),
                ],
                1
              ),
              !(_vm.isLogin || _vm.isGuestLogin)
                ? _c(
                    VBtn,
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { fab: "", icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.loginPage()
                        },
                      },
                    },
                    [
                      _c(
                        VAvatar,
                        { attrs: { size: "40" } },
                        [
                          _c(VIcon, { attrs: { "x-large": "" } }, [
                            _vm._v("mdi-account-circle"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isLogin || _vm.isGuestLogin
                ? _c(
                    VMenu,
                    {
                      attrs: { "open-on-hover": "", "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  VBadge,
                                  {
                                    staticStyle: { "margin-right": "30px" },
                                    attrs: {
                                      value: _vm.isLogin,
                                      color: "green",
                                      top: _vm.LoginHover,
                                      left: _vm.LoginHover,
                                      bottom: !_vm.LoginHover,
                                      overlap: "",
                                      transition: "slide-x-transition",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "badge",
                                          fn: function () {
                                            return [
                                              _vm.isLogin
                                                ? _c("div", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.getSavedCoin)
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      VHover,
                                      {
                                        staticStyle: {
                                          "margin-right": "-20px",
                                        },
                                        model: {
                                          value: _vm.LoginHover,
                                          callback: function ($$v) {
                                            _vm.LoginHover = $$v
                                          },
                                          expression: "LoginHover",
                                        },
                                      },
                                      [
                                        _c(
                                          VAvatar,
                                          _vm._g({ attrs: { size: "40" } }, on),
                                          [
                                            _vm.loadingMigrateHistory
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(VProgressCircular, {
                                                      attrs: {
                                                        indeterminate: "",
                                                        color: "green",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c("div", [
                                                  _vm.userInfo &&
                                                  _vm.userInfo.profile
                                                    ? _c("div", [
                                                        _c("img", {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            src: _vm.userInfo
                                                              .profile,
                                                          },
                                                        }),
                                                      ])
                                                    : _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            VIcon,
                                                            {
                                                              attrs: {
                                                                "x-large": "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-account-circle"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3345929027
                      ),
                      model: {
                        value: _vm.openMenu,
                        callback: function ($$v) {
                          _vm.openMenu = $$v
                        },
                        expression: "openMenu",
                      },
                    },
                    [
                      _c(
                        VList,
                        { staticStyle: { width: "310px" } },
                        [
                          _c(
                            VListItemGroup,
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "small",
                                    cursor: "default",
                                    display: "table-cell",
                                    "padding-left": "16px",
                                  },
                                },
                                [
                                  _vm.isLogin
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.userInfo.email) +
                                            " (" +
                                            _vm._s(_vm.userInfo.providerUid) +
                                            ")"
                                        ),
                                      ])
                                    : _vm.isGuestLogin
                                    ? _c("div", [
                                        _vm._v(
                                          "(GUEST) " +
                                            _vm._s(_vm.userInfo.email)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c(VDivider, {
                                staticStyle: { "margin-top": "5px" },
                              }),
                              _vm._l(_vm.paymentLists, function (item, index) {
                                return _c(
                                  VListItem,
                                  {
                                    key: index,
                                    staticClass: "text-reader",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onClickLoginMenu(item.key)
                                      },
                                    },
                                  },
                                  [
                                    _c(VListItemTitle, [
                                      _vm._v(_vm._s(_vm.$t(item.display))),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.courseNavi && _vm.$route.path.includes("eventstorming")
            ? _c("course-navigator", {
                attrs: { value: _vm.naviObject },
                on: {
                  "update:value": function ($event) {
                    _vm.naviObject = $event
                  },
                },
              })
            : _vm._e(),
          _c(
            VContent,
            {
              style:
                _vm.headerFloating == true
                  ? "margin-top:-64px;"
                  : "margin-top:0px;",
            },
            [
              _vm.progressValue
                ? _c(VProgressLinear, {
                    attrs: {
                      fixed: "",
                      indeterminate: "",
                      color: "orange",
                      height: "10",
                    },
                  })
                : _vm._e(),
              _c(
                VContainer,
                {
                  style:
                    _vm.labTool == "quiz" ? "background-color: #E3F2FD" : "",
                  attrs: { fluid: "", "fill-height": "" },
                },
                [
                  _c(
                    VLayout,
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c("router-view"),
                          _vm.terminal
                            ? _c(
                                "div",
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticStyle: {
                                        position: "fixed",
                                        height: "5%",
                                        top: "65%",
                                        right: "5px",
                                      },
                                      attrs: { color: "error" },
                                      on: { click: _vm.terminalOff },
                                    },
                                    [_c(VIcon, [_vm._v("mdi-close")])],
                                    1
                                  ),
                                  _vm.terminalUrl
                                    ? _c("iframe", {
                                        staticStyle: {
                                          height: "30%",
                                          right: "0",
                                          bottom: "0",
                                          display: "block",
                                          position: "fixed",
                                        },
                                        style: {
                                          width: _vm.terminalWidth + "px",
                                        },
                                        attrs: {
                                          id: "eventTerminal",
                                          src: _vm.terminalUrl,
                                        },
                                        on: { load: _vm.onLoad },
                                      })
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            VSkeletonLoader,
                                            _vm._b(
                                              {
                                                attrs: { type: "card-avatar" },
                                              },
                                              "v-skeleton-loader",
                                              _vm.attrs,
                                              false
                                            )
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vue-context",
            { ref: "menu", on: { open: _vm.onOpen, close: _vm.onClose } },
            [
              _c(
                VList,
                [
                  _c(
                    VListItem,
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onClick($event.target.innerText)
                        },
                      },
                    },
                    [
                      _c(
                        VListItemContent,
                        [
                          _c(VListItemTitle, {
                            domProps: { textContent: _vm._s("강력 새로고침") },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { "max-width": "700px" },
              on: { "click:outside": _vm.closeRefundDialog },
              model: {
                value: _vm.refundDialog,
                callback: function ($$v) {
                  _vm.refundDialog = $$v
                },
                expression: "refundDialog",
              },
            },
            [
              _c(
                VCard,
                { staticStyle: { width: "100%", height: "400px" } },
                [
                  _c("RefundItem", {
                    attrs: { refundInfo: _vm.refundInfo },
                    on: { close: _vm.closeRefundDialog },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { "max-width": "700px" },
              on: { "click:outside": _vm.closeToolTime },
              model: {
                value: _vm.openPaymentTime,
                callback: function ($$v) {
                  _vm.openPaymentTime = $$v
                },
                expression: "openPaymentTime",
              },
            },
            [
              _c(
                VCard,
                { staticStyle: { width: "100%", height: "450px" } },
                [_c("PaymentToolTime", { on: { close: _vm.closeToolTime } })],
                1
              ),
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { "max-width": "650px" },
              on: { "click:outside": _vm.closeGetCoin },
              model: {
                value: _vm.showGetCoin,
                callback: function ($$v) {
                  _vm.showGetCoin = $$v
                },
                expression: "showGetCoin",
              },
            },
            [
              _c(
                VCard,
                { staticStyle: { width: "100%", height: "200px" } },
                [_c("GetCoin", { on: { close: _vm.closeGetCoin } })],
                1
              ),
            ],
            1
          ),
          _c(
            VDialog,
            {
              staticStyle: { width: "700px", height: "700px" },
              model: {
                value: _vm.infoDialog,
                callback: function ($$v) {
                  _vm.infoDialog = $$v
                },
                expression: "infoDialog",
              },
            },
            [
              _c(
                VCard,
                [
                  _c(VCardTitle, { staticClass: "headline" }, [
                    _vm._v("How to use EventStorming-tool?"),
                  ]),
                  _c(
                    VCarousel,
                    {
                      attrs: { "show-arrows": "true" },
                      model: {
                        value: _vm.infoNum,
                        callback: function ($$v) {
                          _vm.infoNum = $$v
                        },
                        expression: "infoNum",
                      },
                    },
                    _vm._l(_vm.infoSlider, function (slider, i) {
                      return _c(VCarouselItem, {
                        key: slider,
                        attrs: { src: slider },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { "max-width": "400" },
              on: { "click:outside": _vm.closeSubscriptionDialog },
              model: {
                value: _vm.subscriptionDialog,
                callback: function ($$v) {
                  _vm.subscriptionDialog = $$v
                },
                expression: "subscriptionDialog",
              },
            },
            [
              _c(
                VCard,
                { staticStyle: { width: "100%", height: "100%" } },
                [
                  _c(
                    VRow,
                    {
                      staticStyle: {
                        "justify-content": "space-between",
                        "margin-left": "0px",
                        "margin-right": "0px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "main-title",
                          staticStyle: {
                            "margin-left": "5%",
                            "padding-top": "20px",
                          },
                        },
                        [_vm._v(" 구독 구매")]
                      ),
                    ]
                  ),
                  _c(
                    VRow,
                    {
                      staticStyle: {
                        "margin-left": "3%",
                        "margin-right": "3%",
                        "margin-top": "3%",
                      },
                    },
                    [
                      _c("SubscriptionItemTemplate", {
                        on: { close: _vm.closeSubscriptionDialog },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { width: "900" },
              on: {
                "click:outside": function ($event) {
                  return _vm.closeLoginDialog()
                },
              },
              model: {
                value: _vm.loginDialog,
                callback: function ($$v) {
                  _vm.loginDialog = $$v
                },
                expression: "loginDialog",
              },
            },
            [
              _c("Login", {
                attrs: { loginMsg: _vm.loginText },
                on: {
                  close: function ($event) {
                    return _vm.closeLoginDialog()
                  },
                  login: _vm.login,
                },
              }),
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: {
                fullscreen: "",
                "hide-overlay": "",
                transition: "dialog-bottom-transition",
              },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VToolbar,
                    { attrs: { dark: "", color: "primary" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                              _vm.kubeToken = ""
                              _vm.kubeHost = ""
                            },
                          },
                        },
                        [_c(VIcon, [_vm._v("close")])],
                        1
                      ),
                      _c(VToolbarTitle, [_vm._v("Settings")]),
                      _c(VSpacer),
                      _c(
                        VToolbarItems,
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { dark: "", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveSetting()
                                },
                              },
                            },
                            [_vm._v("Save")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VList,
                    { attrs: { "three-line": "", subheader: "" } },
                    [
                      _c(VSubheader, [_vm._v("Connection Setting")]),
                      _c(
                        "v-list-tile",
                        { attrs: { avatar: "" } },
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c(
                                "v-list-tile-sub-title",
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      label: "Kube Host",
                                      hint: "Ex) https://api.k8s.bzdvops.com",
                                      outline: "",
                                    },
                                    model: {
                                      value: _vm.kubeHost,
                                      callback: function ($$v) {
                                        _vm.kubeHost = $$v
                                      },
                                      expression: "kubeHost",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile",
                        { attrs: { avatar: "" } },
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c(
                                "v-list-tile-sub-title",
                                [
                                  _c(VTextField, {
                                    attrs: { label: "Kube Token", outline: "" },
                                    model: {
                                      value: _vm.kubeToken,
                                      callback: function ($$v) {
                                        _vm.kubeToken = $$v
                                      },
                                      expression: "kubeToken",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VSnackbar,
            {
              attrs: { color: "error", timeout: 10000 },
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [
              _vm._v("\n        " + _vm._s(_vm.snackbarText) + "\n        "),
              _c(
                VBtn,
                {
                  attrs: { dark: "", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.snackbar = false
                    },
                  },
                },
                [_vm._v("\n            Close\n        ")]
              ),
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { "hide-overlay": "" },
              model: {
                value: _vm.podStatusDialog,
                callback: function ($$v) {
                  _vm.podStatusDialog = $$v
                },
                expression: "podStatusDialog",
              },
            },
            [
              _c(
                VCard,
                { attrs: { width: "100%" } },
                [
                  _c(
                    VToolbar,
                    { attrs: { dark: "", color: "primary" } },
                    [
                      _c(VToolbarTitle, [
                        _vm._v(_vm._s(_vm.hashName) + " Events"),
                      ]),
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              _vm.podStatusDialog = false
                            },
                          },
                        },
                        [_c(VIcon, [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VCardText,
                    { staticStyle: { "max-height": "500px", width: "100%" } },
                    [
                      _vm.podStatusDialog
                        ? _c("pod-events", {
                            attrs: { "hash-name": _vm.hashName },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.progressing
            ? _c(
                VAlert,
                {
                  staticStyle: {
                    position: "fixed",
                    bottom: "0",
                    "margin-bottom": "0px",
                    "z-index": "999",
                    width: "100%",
                    "background-color": "#ffffff !important",
                  },
                  attrs: {
                    type: "info",
                    outlined: "",
                    border: "top",
                    dismissible: "",
                  },
                },
                [
                  _c("h3", [_vm._v(_vm._s(_vm.$t("word.progressState")))]),
                  _c(
                    VStepper,
                    {
                      staticStyle: {
                        "box-shadow":
                          "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)",
                      },
                      model: {
                        value: _vm.step,
                        callback: function ($$v) {
                          _vm.step = $$v
                        },
                        expression: "step",
                      },
                    },
                    [
                      _c(
                        VStepperHeader,
                        { attrs: { flat: "" } },
                        [
                          _vm._l(_vm.steps, function (n, index) {
                            return [
                              n.text == "IDE Creating..."
                                ? _c(
                                    VStepperStep,
                                    {
                                      key: `${n.text}`,
                                      attrs: {
                                        complete: _vm.step > index + 1,
                                        step: index + 1,
                                        rules: [() => !n.error],
                                        "non-linear": "",
                                        color: "success",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.podStatusDialog = true
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(n.text) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _c(
                                    VStepperStep,
                                    {
                                      key: `${n.text}`,
                                      attrs: {
                                        complete: _vm.step > index + 1,
                                        step: index + 1,
                                        rules: [() => !n.error],
                                        "non-linear": "",
                                        color: "success",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(n.text) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                              index != _vm.steps.length - 1 &&
                              index !== _vm.steps
                                ? _c(VDivider, { key: index })
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }