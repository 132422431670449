var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.value && _vm.value._type == "org.uengine.uml.ClassDiagram"
        ? _c("class-designer", {
            attrs: { value: _vm.value, id: _vm.path, backend: _vm.backend },
          })
        : _vm._e(),
      _vm.value && _vm.value._type == "org.uengine.kernel.ProcessDefinition"
        ? _c("process-designer", {
            attrs: { value: _vm.value, id: _vm.path, backend: _vm.backend },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }