import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(VDivider),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "padding-bottom": "10px" } },
        [
          _c(
            VCol,
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "13px",
                    padding: "0px",
                    opacity: "0.8",
                  },
                },
                [
                  !_vm.isForeign
                    ? _c("div", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("company.name")) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("word.address")) +
                            " : " +
                            _vm._s(_vm.$t("company.address")) +
                            ", " +
                            _vm._s(_vm.$t("word.number")) +
                            " : " +
                            _vm._s(_vm.$t("company.number"))
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("word.ceo")) +
                            "  : " +
                            _vm._s(_vm.$t("company.ceo")) +
                            ",  " +
                            _vm._s(_vm.$t("word.registrationNumber")) +
                            " : " +
                            _vm._s(_vm.$t("company.registrationNumber"))
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("info.refundComment")) +
                            " / " +
                            _vm._s(_vm.$t("word.refundUser")) +
                            " : " +
                            _vm._s(_vm.$t("company.refundUser")) +
                            "  (" +
                            _vm._s(_vm.$t("company.refundNumber")) +
                            ")"
                        ),
                        _c("br"),
                      ])
                    : _vm._e(),
                  _c("div", [
                    _vm._v(" Copyright © uEngine All Rights Reserved "),
                  ]),
                ]
              ),
              _c(
                VRow,
                {
                  staticStyle: { margin: "0px" },
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "footer-btn",
                      on: {
                        click: function ($event) {
                          return _vm.provision()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("word.terms")))]
                  ),
                  _c("div", [_vm._v("  |  ")]),
                  _c(
                    "div",
                    {
                      staticClass: "footer-btn",
                      on: {
                        click: function ($event) {
                          return _vm.provision()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("word.privacy")))]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }