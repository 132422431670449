import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "margin-top": "10px" } }, [
    _c(
      "div",
      { staticClass: "auto-modeling-message-border" },
      [
        _c(
          VCard,
          { staticClass: "auto-modeling-message-card" },
          [
            _c(
              VCardText,
              { staticClass: "auto-modeling-message" },
              [
                _c(
                  "vue-typed-js",
                  {
                    attrs: {
                      strings: [_vm.$t("autoModeling.user")],
                      typeSpeed: 10,
                      showCursor: false,
                    },
                  },
                  [_c("span", { staticClass: "typing" })]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          VCol,
          { staticStyle: { padding: "0" } },
          [
            _c(
              VCard,
              {
                staticStyle: {
                  "margin-top": "10px",
                  display: "inline-flex",
                  "background-color": "#DAF5FF",
                  padding: "10px",
                  "min-width": "200px",
                  "min-height": "200px",
                },
              },
              [
                _c(
                  VCol,
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          margin: "0px 0px 15px 0px",
                        },
                      },
                      [
                        _vm.done
                          ? _c(
                              VBtn,
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.generate()
                                  },
                                },
                              },
                              [
                                _c(
                                  VIcon,
                                  { staticClass: "auto-modeling-btn-icon" },
                                  [_vm._v("mdi-refresh")]
                                ),
                                _vm._v("Try again"),
                              ],
                              1
                            )
                          : _c(
                              VBtn,
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.stop()
                                  },
                                },
                              },
                              [
                                _c(VProgressCircular, {
                                  staticClass:
                                    "auto-modeling-stop-loading-icon",
                                  attrs: { indeterminate: "" },
                                }),
                                _vm._v("Stop Generating"),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                    _c(
                      VRow,
                      { attrs: { lg: "3", md: "4", sm: "6", cols: "12" } },
                      _vm._l(_vm.value.personas, function (persona) {
                        return _vm.value.personas
                          ? _c(
                              VCol,
                              { key: persona.name },
                              [
                                _c(
                                  VCard,
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "min-width": "200px",
                                      "min-height": "200px",
                                    },
                                    style:
                                      _vm.state.persona == persona.persona
                                        ? "border: solid darkturquoise;"
                                        : "",
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "20px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              (!_vm.personaEditMode &&
                                                _vm.selectedEditPersona !=
                                                  persona.scenario) ||
                                              _vm.selectedEditPersona !=
                                                persona.scenario
                                                ? _c(
                                                    VIcon,
                                                    {
                                                      staticStyle: {
                                                        margin: "5px",
                                                        float: "right",
                                                      },
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editPersona(
                                                            persona
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("mdi-pencil")]
                                                  )
                                                : _vm._e(),
                                              _vm.personaEditMode &&
                                              _vm.selectedEditPersona ===
                                                persona.scenario
                                                ? _c(
                                                    VIcon,
                                                    {
                                                      staticStyle: {
                                                        margin: "5px",
                                                        float: "right",
                                                      },
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.savePersona()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("mdi-content-save")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm.selectedEditPersona ===
                                          persona.scenario
                                            ? _c(
                                                VCardTitle,
                                                [
                                                  _c(VTextarea, {
                                                    attrs: {
                                                      "no-resize": "",
                                                      rows: "1",
                                                      "row-height": "15",
                                                    },
                                                    model: {
                                                      value: persona.persona,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          persona,
                                                          "persona",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "persona.persona",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c(
                                                VCardTitle,
                                                { staticClass: "text-h5;" },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(persona.persona) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                          _c(
                                            VAvatar,
                                            {
                                              staticClass: "ma-3",
                                              attrs: {
                                                size: "50",
                                                rounded: "0",
                                              },
                                            },
                                            [
                                              persona.gender
                                                ? _c(VImg, {
                                                    attrs: {
                                                      src: persona.avatar,
                                                    },
                                                  })
                                                : _c(VImg, {
                                                    attrs: {
                                                      src: "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-7.jpg",
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                          _vm.selectedEditPersona ===
                                          persona.scenario
                                            ? _c(
                                                VCardSubtitle,
                                                [
                                                  _c(VTextarea, {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                    },
                                                    attrs: {
                                                      "no-resize": "",
                                                      rows: "4",
                                                    },
                                                    model: {
                                                      value:
                                                        persona.description,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          persona,
                                                          "description",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "persona.description",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c(VCardSubtitle, [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(persona.description)
                                                ),
                                              ]),
                                          _c(
                                            VCardActions,
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass: "ms-2",
                                                  staticStyle: {
                                                    position: "absolute",
                                                    right: "10px",
                                                    bottom: "10px",
                                                  },
                                                  attrs: {
                                                    variant: "outlined",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectPersona(
                                                        persona
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            NEXT\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e()
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.step > 1
              ? _c(
                  VCard,
                  {
                    staticStyle: {
                      "white-space": "pre",
                      "margin-top": "10px",
                      width: "90%",
                      float: "right",
                    },
                  },
                  [
                    _c(VCardSubtitle, [
                      _vm._v(_vm._s(_vm.$t("autoModeling.scenario"))),
                    ]),
                    _c(
                      VCardText,
                      {
                        staticStyle: {
                          "max-height": "1385px",
                          "overflow-y": "auto",
                          padding: "0px 10px 10px 10px",
                        },
                      },
                      [
                        _c(VTextarea, {
                          staticClass: "gs-auto-modeling-text-box elevation-0",
                          attrs: {
                            flat: "",
                            dense: "",
                            "auto-grow": "",
                            rows: "2",
                            solo: "",
                          },
                          model: {
                            value: _vm.state.scenario,
                            callback: function ($$v) {
                              _vm.$set(_vm.state, "scenario", $$v)
                            },
                            expression: "state.scenario",
                          },
                        }),
                        _c(
                          VBtn,
                          {
                            staticClass: "cjm-create-btn",
                            staticStyle: {
                              float: "right",
                              "margin-top": "5px",
                            },
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.jump()
                              },
                            },
                          },
                          [
                            _vm._v("Create Model"),
                            _c(VIcon, [_vm._v("mdi-arrow-right")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }