import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "10px" } },
    [
      _c(
        "div",
        { staticClass: "auto-modeling-message-border" },
        [
          _c(
            VCol,
            { staticClass: "auto-modeling-message-box" },
            [
              _c(
                VCard,
                { staticClass: "auto-modeling-message-card" },
                [
                  _c(
                    VCardText,
                    { staticClass: "auto-modeling-message" },
                    [
                      _c(
                        "vue-typed-js",
                        {
                          attrs: {
                            strings: [_vm.$t("autoModeling.selectMessage1")],
                            typeSpeed: 10,
                            showCursor: false,
                          },
                          on: {
                            onComplete: function ($event) {
                              _vm.state.firstMessageIsTyping = false
                            },
                          },
                        },
                        [_c("span", { staticClass: "typing" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "auto-modeling-message-box" },
            [
              !_vm.state.firstMessageIsTyping
                ? _c(
                    VCard,
                    { staticClass: "auto-modeling-message-card" },
                    [
                      _c(
                        VCardText,
                        { staticClass: "auto-modeling-message" },
                        [
                          _c(
                            "vue-typed-js",
                            {
                              attrs: {
                                strings: [
                                  _vm.$t("autoModeling.selectMessage2"),
                                ],
                                typeSpeed: 5,
                                showCursor: false,
                              },
                              on: {
                                onComplete: function ($event) {
                                  _vm.state.secondMessageIsTyping = false
                                },
                              },
                            },
                            [_c("span", { staticClass: "typing" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.state.secondMessageIsTyping
        ? _c(
            VCard,
            { staticClass: "auto-modeling-user-story-card" },
            [
              _c(VCardSubtitle, [
                _vm._v(_vm._s(_vm.$t("autoModeling.explanation"))),
              ]),
              _c(
                VCardText,
                { staticClass: "auto-modling-textarea" },
                [
                  _c(VTextarea, {
                    staticClass: "elevation-0",
                    attrs: {
                      flat: "",
                      dense: "",
                      "auto-grow": "",
                      rows: "2",
                      solo: "",
                    },
                    model: {
                      value: _vm.value.userStory,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "userStory", $$v)
                      },
                      expression: "value.userStory",
                    },
                  }),
                ],
                1
              ),
              !_vm.done
                ? _c(
                    VBtn,
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.stop()
                        },
                      },
                    },
                    [
                      _c(VProgressCircular, {
                        staticClass: "auto-modeling-stop-loading-icon",
                        attrs: { indeterminate: "" },
                      }),
                      _vm._v("Stop generating"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.done
                ? _c(
                    VCardActions,
                    { staticClass: "auto-modeling-btn-box" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "auto-modeling-btn",
                          on: {
                            click: function ($event) {
                              return _vm.generate()
                            },
                          },
                        },
                        [
                          _c(
                            VIcon,
                            { staticClass: "auto-modeling-btn-icon" },
                            [_vm._v("mdi-refresh")]
                          ),
                          _vm._v("Try again"),
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "auto-modeling-btn",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.jump()
                            },
                          },
                        },
                        [
                          _vm._v("Create Model"),
                          _c(
                            VIcon,
                            { staticClass: "auto-modeling-btn-icon" },
                            [_vm._v("mdi-arrow-right")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "auto-modeling-message-card",
          staticStyle: { "margin-top": "25px", height: "100%", width: "20%" },
        },
        [
          _vm.value && _vm.value.modelList && _vm.value.modelList.length > 0
            ? _c(
                VCol,
                {
                  staticStyle: {
                    height: "100%",
                    "align-items": "center",
                    margin: "2px",
                    width: "100%",
                  },
                },
                _vm._l(_vm.value.modelList, function (id) {
                  return _c(
                    "div",
                    { key: id },
                    [
                      _c("jump-to-model-lists-card", {
                        attrs: { id: id, path: "storming" },
                        on: { deleteModel: _vm.deleteModel },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }