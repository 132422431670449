import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "10px" } },
    [
      _c(
        VTabs,
        {
          attrs: { "bg-color": "primary" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(VTab, { attrs: { value: "mainImage" } }, [
            _vm._v("Main Image"),
          ]),
          _c(VTab, { attrs: { value: "colorPalettes" } }, [
            _vm._v("Color Palettes"),
          ]),
          _c(VTab, { attrs: { value: "fonts" } }, [_vm._v("Fonts")]),
        ],
        1
      ),
      _c(
        VTabsItems,
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(VTabItem, { key: "mainIamge" }, [
            _c(
              "div",
              { staticClass: "auto-modeling-message-border" },
              [
                _c(
                  VRow,
                  {
                    staticStyle: { "margin-top": "0px", "margin-left": "0px" },
                  },
                  [
                    _c(
                      VCard,
                      {
                        staticClass: "auto-modeling-message-card",
                        staticStyle: {
                          "margin-top": "10px",
                          "margin-bottom": "10px",
                        },
                      },
                      [
                        _c(
                          VCardText,
                          { staticClass: "auto-modeling-message" },
                          [
                            _c(
                              "vue-typed-js",
                              {
                                attrs: {
                                  strings: [
                                    _vm.$t("UIWizardDialoger.imageMessage"),
                                  ],
                                  typeSpeed: 10,
                                  showCursor: false,
                                },
                                on: {
                                  onComplete: function ($event) {
                                    _vm.firstMessageIsTyping = false
                                  },
                                },
                              },
                              [_c("span", { staticClass: "typing" })]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  VRow,
                  {
                    staticStyle: {
                      position: "relative",
                      right: "-8%",
                      "margin-bottom": "0px",
                    },
                  },
                  [
                    !_vm.firstMessageIsTyping
                      ? _c(
                          VCard,
                          {
                            staticClass: "auto-modeling-user-story-card",
                            staticStyle: { bottom: "10px", top: "-5px" },
                          },
                          [
                            _c(
                              VCardText,
                              {
                                staticClass: "auto-modling-textarea",
                                staticStyle: { "margin-bottom": "-30px" },
                              },
                              [
                                _c(VTextarea, {
                                  staticClass: "elevation-0",
                                  attrs: {
                                    flat: "",
                                    dense: "",
                                    "auto-grow": "",
                                    rows: "2",
                                    solo: "",
                                  },
                                  model: {
                                    value: _vm.imageDescription,
                                    callback: function ($$v) {
                                      _vm.imageDescription = $$v
                                    },
                                    expression: "imageDescription",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              VRow,
                              { staticStyle: { "margin-bottom": "10px" } },
                              [
                                _c(VSpacer),
                                _c(
                                  VBtn,
                                  {
                                    staticStyle: { "margin-right": "15px" },
                                    on: { click: _vm.generateImageDescription },
                                  },
                                  [_vm._v("(RE)GENERATE IMAGE DESCRIPTION")]
                                ),
                                _c(
                                  VBtn,
                                  {
                                    staticStyle: { "margin-right": "25px" },
                                    attrs: { color: "primary" },
                                    on: { click: _vm.generateImage },
                                  },
                                  [_vm._v("GENERATE IMAGE")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                !_vm.isLoading
                  ? _c(
                      VRow,
                      _vm._l(_vm.value.images, function (image) {
                        return _c(
                          VCol,
                          { key: image.url },
                          [
                            _c(
                              VCard,
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "min-width": "200px",
                                  "min-height": "200px",
                                  "padding-top": "5px",
                                  "padding-bottom": "10px",
                                },
                                style:
                                  image === _vm.value.selectedImage
                                    ? "border: solid darkturquoise;"
                                    : "",
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "256px",
                                    height: "256px",
                                  },
                                  attrs: { src: image.url },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "36px",
                                      width: "100%",
                                    },
                                  },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          right: "10px",
                                        },
                                        attrs: {
                                          variant: "outlined",
                                          size: "small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectImage(image)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    SELECT\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _c(
                      VRow,
                      [
                        _c(
                          VCol,
                          [
                            _c(VSkeletonLoader, {
                              ref: "skeleton",
                              staticClass: "mx-auto",
                              attrs: { type: "card" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
          ]),
          _c(
            VTabItem,
            { key: "colorPalettes" },
            [
              _c(
                VTabs,
                {
                  attrs: { "bg-color": "primary" },
                  model: {
                    value: _vm.paletteTab,
                    callback: function ($$v) {
                      _vm.paletteTab = $$v
                    },
                    expression: "paletteTab",
                  },
                },
                [
                  _c(
                    VTab,
                    {
                      attrs: { value: "AI color Palettes" },
                      on: {
                        click: function ($event) {
                          return _vm.changePreViewMode()
                        },
                      },
                    },
                    [_vm._v("AI Palettes")]
                  ),
                  _c(
                    VTab,
                    {
                      attrs: { value: "Default Palettes" },
                      on: {
                        click: function ($event) {
                          return _vm.changePaletteMode()
                        },
                      },
                    },
                    [_vm._v("Default Palettes")]
                  ),
                ],
                1
              ),
              _c(
                VTabsItems,
                {
                  model: {
                    value: _vm.paletteTab,
                    callback: function ($$v) {
                      _vm.paletteTab = $$v
                    },
                    expression: "paletteTab",
                  },
                },
                [
                  _c(VTabItem, { key: "AI color Palettes" }, [
                    _c(
                      "div",
                      { staticClass: "auto-modeling-message-border" },
                      [
                        _c(
                          VCard,
                          {
                            staticClass: "auto-modeling-message-card",
                            staticStyle: { "margin-top": "10px" },
                          },
                          [
                            _c(
                              VCardText,
                              { staticClass: "auto-modeling-message" },
                              [
                                _c(
                                  "vue-typed-js",
                                  {
                                    attrs: {
                                      strings: [
                                        _vm.$t(
                                          "UIWizardDialoger.colorPaletteMessage"
                                        ),
                                      ],
                                      typeSpeed: 10,
                                      showCursor: false,
                                    },
                                  },
                                  [_c("span", { staticClass: "typing" })]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          { staticStyle: { padding: "0" } },
                          [
                            _c(
                              VCard,
                              {
                                staticStyle: {
                                  "margin-top": "10px",
                                  display: "inline-flex",
                                  "background-color": "#DAF5FF",
                                  padding: "10px",
                                  "min-width": "200px",
                                  "min-height": "200px",
                                },
                              },
                              [
                                _c(
                                  VCol,
                                  [
                                    _c(
                                      VRow,
                                      _vm._l(
                                        _vm.value.palettes,
                                        function (palette) {
                                          return _c(
                                            VCol,
                                            {
                                              key: palette.paletteName,
                                              attrs: {
                                                cols: _vm.columnPaletteWidth,
                                              },
                                            },
                                            [
                                              _c(
                                                VCard,
                                                {
                                                  staticStyle: {
                                                    padding:
                                                      "0px 10px 0px 10px",
                                                  },
                                                  style:
                                                    palette ===
                                                    _vm.value.selectedPalette
                                                      ? "border: solid darkturquoise;"
                                                      : "",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "padding-bottom":
                                                          "20px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VCardTitle,
                                                        {
                                                          staticClass:
                                                            "text-h5;",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        " +
                                                              _vm._s(
                                                                palette.paletteName
                                                              ) +
                                                              "\n                                                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        palette.colors,
                                                        function (color, key) {
                                                          return _c(
                                                            "div",
                                                            [
                                                              color
                                                                ? _c(
                                                                    VRow,
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-bottom":
                                                                            "10px",
                                                                        },
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        VCol,
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              cols: "2",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              style:
                                                                                _vm.circleStyle(
                                                                                  color.colorCode
                                                                                ),
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.editColor(
                                                                                      palette,
                                                                                      key
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        VCol,
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              cols: "10",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "font-weight":
                                                                                    "700",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                    " +
                                                                                  _vm._s(
                                                                                    key
                                                                                  ) +
                                                                                  " : " +
                                                                                  _vm._s(
                                                                                    color.colorName
                                                                                  ) +
                                                                                  "\n                                                                "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                    " +
                                                                                  _vm._s(
                                                                                    color.description
                                                                                  ) +
                                                                                  "\n                                                                "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm.editMode &&
                                                                          _vm.selectedPaletteName ===
                                                                            palette.paletteName &&
                                                                          _vm.selecctedEditColor ===
                                                                            key
                                                                            ? _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    VColorPicker,
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "hide-inputs":
                                                                                            "",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            color.colorCode,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                color,
                                                                                                "colorCode",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "color.colorCode",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    VIcon,
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "black",
                                                                                          float:
                                                                                            "right",
                                                                                          "margin-top":
                                                                                            "-6%",
                                                                                          "margin-right":
                                                                                            "6%",
                                                                                          "font-size":
                                                                                            "18px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.saveColor()
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-content-save"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        VCardActions,
                                                        [
                                                          _c(
                                                            VBtn,
                                                            {
                                                              staticClass:
                                                                "ms-2",
                                                              staticStyle: {
                                                                position:
                                                                  "absolute",
                                                                right: "10px",
                                                                bottom: "10px",
                                                              },
                                                              attrs: {
                                                                variant:
                                                                  "outlined",
                                                                size: "small",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectPalette(
                                                                      palette
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            SELECT\n                                                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          margin: "15px 0px 0px 0px",
                                        },
                                      },
                                      [
                                        _vm.done
                                          ? _c(
                                              VBtn,
                                              {
                                                staticStyle: { float: "right" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.generate()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  VIcon,
                                                  {
                                                    staticClass:
                                                      "auto-modeling-btn-icon",
                                                  },
                                                  [_vm._v("mdi-refresh")]
                                                ),
                                                _vm._v("Try again"),
                                              ],
                                              1
                                            )
                                          : _c(
                                              VBtn,
                                              {
                                                staticStyle: { float: "right" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.stop()
                                                  },
                                                },
                                              },
                                              [
                                                _c(VProgressCircular, {
                                                  staticClass:
                                                    "auto-modeling-stop-loading-icon",
                                                  attrs: { indeterminate: "" },
                                                }),
                                                _vm._v("Stop Generating"),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    VTabItem,
                    { key: "Default Palettes" },
                    [
                      _vm.paletteMode
                        ? _c(
                            VCol,
                            [
                              _c(
                                VCol,
                                { staticStyle: { padding: "0" } },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticStyle: {
                                        "margin-top": "10px",
                                        "background-color": "#DAF5FF",
                                        padding: "10px",
                                        "min-width": "200px",
                                        "min-height": "200px",
                                      },
                                    },
                                    [
                                      _c(
                                        VCol,
                                        [
                                          _c(
                                            VRow,
                                            _vm._l(
                                              _vm.firstPreviewPalettes,
                                              function (previewColor, index) {
                                                return index < 3
                                                  ? _c(
                                                      VCol,
                                                      {
                                                        key: previewColor.paletteName,
                                                        attrs: {
                                                          cols: _vm.columnWidth,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          VCard,
                                                          {
                                                            staticStyle: {
                                                              padding:
                                                                "0px 10px 0px 10px",
                                                            },
                                                            style:
                                                              previewColor ===
                                                              _vm.selectedPaletteTheme
                                                                ? "border: solid darkturquoise;"
                                                                : "",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "padding-bottom":
                                                                    "20px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VCardTitle,
                                                                  {
                                                                    staticClass:
                                                                      "text-h5;",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                        " +
                                                                        _vm._s(
                                                                          previewColor.paletteName
                                                                        ) +
                                                                        "\n                                                    "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._l(
                                                                  previewColor.colors,
                                                                  function (
                                                                    color,
                                                                    key
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      [
                                                                        color
                                                                          ? _c(
                                                                              VRow,
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-bottom":
                                                                                      "10px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  VCol,
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        cols: "2",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        style:
                                                                                          _vm.circleStyle(
                                                                                            color.colorCode
                                                                                          ),
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.editColor(
                                                                                                previewColor,
                                                                                                key
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  VCol,
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        cols: "10",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "font-weight":
                                                                                              "700",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                    " +
                                                                                            _vm._s(
                                                                                              key
                                                                                            ) +
                                                                                            " : " +
                                                                                            _vm._s(
                                                                                              color.colorName
                                                                                            ) +
                                                                                            "\n                                                                "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                    " +
                                                                                            _vm._s(
                                                                                              color.description
                                                                                            ) +
                                                                                            "\n                                                                "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm.editMode &&
                                                                                    _vm.selectedPaletteName ===
                                                                                      previewColor.paletteName &&
                                                                                    _vm.selecctedEditColor ===
                                                                                      key
                                                                                      ? _c(
                                                                                          "div",
                                                                                          [
                                                                                            _c(
                                                                                              VColorPicker,
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    "hide-inputs":
                                                                                                      "",
                                                                                                  },
                                                                                                model:
                                                                                                  {
                                                                                                    value:
                                                                                                      color.colorCode,
                                                                                                    callback:
                                                                                                      function (
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          color,
                                                                                                          "colorCode",
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                    expression:
                                                                                                      "color.colorCode",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              VIcon,
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    color:
                                                                                                      "black",
                                                                                                    float:
                                                                                                      "right",
                                                                                                    "margin-top":
                                                                                                      "-6%",
                                                                                                    "margin-right":
                                                                                                      "6%",
                                                                                                    "font-size":
                                                                                                      "18px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    small:
                                                                                                      "",
                                                                                                  },
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.saveColor(
                                                                                                        previewColor
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "mdi-content-save"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                _c(
                                                                  VCardActions,
                                                                  [
                                                                    _c(
                                                                      VRow,
                                                                      [
                                                                        _c(
                                                                          VBtn,
                                                                          {
                                                                            staticClass:
                                                                              "ms-2",
                                                                            staticStyle:
                                                                              {
                                                                                position:
                                                                                  "absolute",
                                                                                right:
                                                                                  "115px",
                                                                                bottom:
                                                                                  "10px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                variant:
                                                                                  "outlined",
                                                                                size: "small",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.selectPaletteTheme(
                                                                                    previewColor
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                SELECT\n                                                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          VBtn,
                                                                          {
                                                                            staticClass:
                                                                              "ms-2",
                                                                            staticStyle:
                                                                              {
                                                                                position:
                                                                                  "absolute",
                                                                                right:
                                                                                  "6px",
                                                                                bottom:
                                                                                  "10px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                variant:
                                                                                  "outlined",
                                                                                size: "small",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.changePaletteMode()
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                PREVIEW\n                                                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                { staticStyle: { padding: "0" } },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticStyle: {
                                        "margin-top": "10px",
                                        "background-color": "#DAF5FF",
                                        padding: "10px",
                                        "min-width": "200px",
                                        "min-height": "200px",
                                      },
                                    },
                                    [
                                      _c(
                                        VCol,
                                        [
                                          _c(
                                            VRow,
                                            _vm._l(
                                              _vm.firstPreviewPalettes,
                                              function (previewColor, index) {
                                                return index >= 3
                                                  ? _c(
                                                      VCol,
                                                      {
                                                        key: previewColor.paletteName,
                                                        attrs: {
                                                          cols: _vm.columnWidth,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          VCard,
                                                          {
                                                            staticStyle: {
                                                              padding:
                                                                "0px 10px 0px 10px",
                                                            },
                                                            style:
                                                              previewColor ===
                                                              _vm.selectedPaletteTheme
                                                                ? "border: solid darkturquoise;"
                                                                : "",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "padding-bottom":
                                                                    "20px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VCardTitle,
                                                                  {
                                                                    staticClass:
                                                                      "text-h5;",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                        " +
                                                                        _vm._s(
                                                                          previewColor.paletteName
                                                                        ) +
                                                                        "\n                                                    "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._l(
                                                                  previewColor.colors,
                                                                  function (
                                                                    color,
                                                                    key
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      [
                                                                        color
                                                                          ? _c(
                                                                              VRow,
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-bottom":
                                                                                      "10px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  VCol,
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        cols: "2",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        style:
                                                                                          _vm.circleStyle(
                                                                                            color.colorCode
                                                                                          ),
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.editColor(
                                                                                                previewColor,
                                                                                                key
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  VCol,
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        cols: "10",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "font-weight":
                                                                                              "700",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                    " +
                                                                                            _vm._s(
                                                                                              key
                                                                                            ) +
                                                                                            " : " +
                                                                                            _vm._s(
                                                                                              color.colorName
                                                                                            ) +
                                                                                            "\n                                                                "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                    " +
                                                                                            _vm._s(
                                                                                              color.description
                                                                                            ) +
                                                                                            "\n                                                                "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm.editMode &&
                                                                                    _vm.selectedPaletteName ===
                                                                                      previewColor.paletteName &&
                                                                                    _vm.selecctedEditColor ===
                                                                                      key
                                                                                      ? _c(
                                                                                          "div",
                                                                                          [
                                                                                            _c(
                                                                                              VColorPicker,
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    "hide-inputs":
                                                                                                      "",
                                                                                                  },
                                                                                                model:
                                                                                                  {
                                                                                                    value:
                                                                                                      color.colorCode,
                                                                                                    callback:
                                                                                                      function (
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          color,
                                                                                                          "colorCode",
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                    expression:
                                                                                                      "color.colorCode",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              VIcon,
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    color:
                                                                                                      "black",
                                                                                                    float:
                                                                                                      "right",
                                                                                                    "margin-top":
                                                                                                      "-6%",
                                                                                                    "margin-right":
                                                                                                      "6%",
                                                                                                    "font-size":
                                                                                                      "18px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    small:
                                                                                                      "",
                                                                                                  },
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.saveColor(
                                                                                                        previewColor
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "mdi-content-save"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                _c(
                                                                  VCardActions,
                                                                  [
                                                                    _c(
                                                                      VRow,
                                                                      [
                                                                        _c(
                                                                          VBtn,
                                                                          {
                                                                            staticClass:
                                                                              "ms-2",
                                                                            staticStyle:
                                                                              {
                                                                                position:
                                                                                  "absolute",
                                                                                right:
                                                                                  "115px",
                                                                                bottom:
                                                                                  "10px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                variant:
                                                                                  "outlined",
                                                                                size: "small",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.selectPaletteTheme(
                                                                                    previewColor
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                SELECT\n                                                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          VBtn,
                                                                          {
                                                                            staticClass:
                                                                              "ms-2",
                                                                            staticStyle:
                                                                              {
                                                                                position:
                                                                                  "absolute",
                                                                                right:
                                                                                  "6px",
                                                                                bottom:
                                                                                  "10px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                variant:
                                                                                  "outlined",
                                                                                size: "small",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.changePaletteMode()
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                PREVIEW\n                                                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            VCol,
                            [
                              _c("Preview", {
                                attrs: {
                                  selectedPalette: _vm.value.selectedPalette,
                                  selectedImage: _vm.value.selectedImage,
                                  selectedFont: _vm.value.selectedFont,
                                  paletteMode: _vm.paletteMode,
                                  firstPreviewPalettes:
                                    _vm.firstPreviewPalettes,
                                  value: _vm.value,
                                },
                                on: {
                                  changePalette: _vm.changePreViewMode,
                                  showOnePalette: _vm.showSinglePalette,
                                },
                                model: {
                                  value: _vm.serviceDescription,
                                  callback: function ($$v) {
                                    _vm.serviceDescription = $$v
                                  },
                                  expression: "serviceDescription",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(VTabItem, { key: "fonts" }, [
            _c(
              "div",
              { staticClass: "auto-modeling-message-border" },
              [
                _c(
                  VCard,
                  {
                    staticClass: "auto-modeling-message-card",
                    staticStyle: { "margin-top": "10px" },
                  },
                  [
                    _c(
                      VCardText,
                      { staticClass: "auto-modeling-message" },
                      [
                        _c(
                          "vue-typed-js",
                          {
                            attrs: {
                              strings: [
                                _vm.$t("UIWizardDialoger.fontPaletteMessage"),
                              ],
                              typeSpeed: 10,
                              showCursor: false,
                            },
                          },
                          [_c("span", { staticClass: "typing" })]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  VRow,
                  _vm._l(_vm.value.fonts, function (font) {
                    return _c(
                      VCol,
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c(
                          VCard,
                          {
                            staticStyle: { "padding-bottom": "20px" },
                            style:
                              font === _vm.value.selectedFont
                                ? "border: solid darkturquoise;"
                                : "",
                          },
                          [
                            _c(
                              VCol,
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "font-weight": "700" } },
                                  [
                                    _vm._v(
                                      _vm._s(font.fontFamilyName) +
                                        " : " +
                                        _vm._s(font.fontTitle)
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(_vm._s(font.recommendedReason)),
                                ]),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-font",
                                    style: _vm.fontStyle(font.fontFamilyName),
                                  },
                                  [
                                    _c("h1", [_vm._v("Aa Bb Cc Dd Ee Ff Gg")]),
                                    _c("h2", [_vm._v("Aa Bb Cc Dd Ee Ff Gg")]),
                                    _c("h3", [_vm._v("Aa Bb Cc Dd Ee Ff Gg")]),
                                    _c("div", [_vm._v("Aa Bb Cc Dd Ee Ff Gg")]),
                                    _c("br"),
                                    _c("h1", [
                                      _vm._v("가 나 다 라 마 바 사 아"),
                                    ]),
                                    _c("h2", [
                                      _vm._v("가 나 다 라 마 바 사 아"),
                                    ]),
                                    _c("h3", [
                                      _vm._v("가 나 다 라 마 바 사 아"),
                                    ]),
                                    _c("div", [
                                      _vm._v("가 나 다 라 마 바 사 아"),
                                    ]),
                                  ]
                                ),
                                _c(
                                  VCardActions,
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "ms-2",
                                        staticStyle: {
                                          position: "absolute",
                                          right: "10px",
                                          bottom: "10px",
                                        },
                                        attrs: {
                                          variant: "outlined",
                                          size: "small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectFont(font)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        SELECT\n                                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  VBtn,
                  {
                    staticStyle: {
                      float: "right",
                      margin: "10px 5px 10px 0px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.regenerateFont()
                      },
                    },
                  },
                  [
                    _c(VIcon, { staticClass: "auto-modeling-btn-icon" }, [
                      _vm._v("mdi-refresh"),
                    ]),
                    _vm._v("Try again"),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.cachedModels.ESGenerator
            ? _c(
                VTabItem,
                { key: "menuImages" },
                _vm._l(_vm.aggregates, function (aggregate, key) {
                  return _c(
                    "div",
                    {
                      key: aggregate.name,
                      staticClass: "auto-modeling-message-border",
                    },
                    [
                      _c(
                        VRow,
                        {
                          staticStyle: {
                            position: "relative",
                            right: "-8%",
                            "margin-bottom": "0px",
                          },
                        },
                        [
                          _c(
                            VCard,
                            {
                              staticClass: "auto-modeling-user-story-card",
                              staticStyle: { bottom: "10px", top: "-5px" },
                            },
                            [
                              _c(VCardSubtitle, [
                                _vm._v(
                                  "Image description for " +
                                    _vm._s(aggregate.name)
                                ),
                              ]),
                              _c(
                                VCardText,
                                {
                                  staticClass: "auto-modling-textarea",
                                  staticStyle: { "margin-bottom": "-30px" },
                                },
                                [
                                  _c(VTextarea, {
                                    attrs: {
                                      dense: "",
                                      "auto-grow": "",
                                      rows: "2",
                                      solo: "",
                                    },
                                    model: {
                                      value:
                                        _vm.aggregates[key].imageDescription,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.aggregates[key],
                                          "imageDescription",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "aggregates[key].imageDescription",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VBtn,
                                {
                                  staticStyle: {
                                    float: "right",
                                    "margin-bottom": "10px",
                                    "margin-right": "15px",
                                  },
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.generateMenuImageDescription(
                                        aggregate.name
                                      )
                                    },
                                  },
                                },
                                [_vm._v("(RE)GENERATE IMAGE DESCRIPTION")]
                              ),
                              !aggregate.isLoading
                                ? _c(
                                    VBtn,
                                    {
                                      staticStyle: {
                                        float: "right",
                                        "margin-bottom": "10px",
                                        "margin-right": "15px",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.generateMenuImage(
                                            aggregate.name
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("(RE)GENERATE IMAGE")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !aggregate.isLoading
                        ? _c(
                            VRow,
                            _vm._l(aggregate.images, function (image) {
                              return _c(
                                VCol,
                                { key: image.url },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "min-width": "200px",
                                        "min-height": "200px",
                                        "padding-top": "5px",
                                        "padding-bottom": "10px",
                                      },
                                      style:
                                        image === aggregate.selectedImage
                                          ? "border: solid darkturquoise;"
                                          : "",
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "256px",
                                          height: "256px",
                                        },
                                        attrs: { src: image.url },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            height: "36px",
                                            width: "100%",
                                          },
                                        },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                position: "absolute",
                                                right: "10px",
                                              },
                                              attrs: {
                                                variant: "outlined",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectMenuImage(
                                                    aggregate.name,
                                                    image
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    SELECT\n                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _c(
                            VRow,
                            [
                              _c(
                                VCol,
                                [
                                  _c(VSkeletonLoader, {
                                    ref: "skeleton",
                                    refInFor: true,
                                    staticClass: "mx-auto",
                                    attrs: { type: "card" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.cachedModels.ESGenerator
            ? _c(
                VTabItem,
                { key: "menuUiStyles" },
                _vm._l(_vm.aggregates, function (aggregate, key) {
                  return _c(
                    "div",
                    {
                      key: aggregate.name,
                      staticClass: "auto-modeling-message-border",
                    },
                    [
                      _c(
                        VRow,
                        {
                          staticStyle: {
                            position: "relative",
                            right: "-8%",
                            "margin-bottom": "0px",
                          },
                        },
                        [
                          _c(
                            VCard,
                            {
                              staticClass: "auto-modeling-user-story-card",
                              staticStyle: { bottom: "10px", top: "-5px" },
                            },
                            [
                              _c(VCardSubtitle, [
                                _vm._v(
                                  "UI Style for " + _vm._s(aggregate.name)
                                ),
                              ]),
                              _c(
                                VCardText,
                                {
                                  staticClass: "auto-modling-textarea",
                                  staticStyle: { "margin-bottom": "-30px" },
                                },
                                [
                                  _c(VTextarea, {
                                    attrs: {
                                      dense: "",
                                      "auto-grow": "",
                                      rows: "2",
                                      solo: "",
                                    },
                                    model: {
                                      value: _vm.aggregates[key].uiStyleJson,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.aggregates[key],
                                          "uiStyleJson",
                                          $$v
                                        )
                                      },
                                      expression: "aggregates[key].uiStyleJson",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VBtn,
                                {
                                  staticStyle: {
                                    float: "right",
                                    "margin-bottom": "10px",
                                    "margin-right": "15px",
                                  },
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.generateUIStyle(aggregate.name)
                                    },
                                  },
                                },
                                [_vm._v("(RE)GENERATE UI STYLE")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.paletteMode && _vm.selectPalette
            ? _c("Preview", {
                attrs: {
                  selectedPalette: _vm.value.selectedPalette,
                  selectedImage: _vm.value.selectedImage,
                  selectedFont: _vm.value.selectedFont,
                  paletteMode: _vm.paletteMode,
                  value: _vm.value,
                },
                on: {
                  changePalette: function ($event) {
                    return _vm.changePaletteMode()
                  },
                },
                model: {
                  value: _vm.serviceDescription,
                  callback: function ($$v) {
                    _vm.serviceDescription = $$v
                  },
                  expression: "serviceDescription",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }