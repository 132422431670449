import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "purchase-item-template",
        {
          attrs: {
            title: "IDE 사용시간 구매",
            itemAmount: _vm.timeToAmount,
            itemPeriod: 0,
            userInfo: _vm.userInfo,
            resourceType: _vm.getItemResourceType,
            paying: _vm.paying,
          },
          on: {
            close: function ($event) {
              return _vm.closeDialog()
            },
            submit: _vm.submit,
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(VAutocomplete, {
                attrs: {
                  items: _vm.ideAmountList,
                  "item-text": "text",
                  "item-value": "amount",
                  label: "IDE 구매시간 ",
                  outlined: "",
                  dense: "",
                },
                model: {
                  value: _vm.ideAmount,
                  callback: function ($$v) {
                    _vm.ideAmount = $$v
                  },
                  expression: "ideAmount",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }