import { render, staticRenderFns } from "./Curve.vue?vue&type=template&id=4ac4c2a6&scoped=true"
import script from "./Curve.vue?vue&type=script&lang=js"
export * from "./Curve.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac4c2a6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kibumpark/Desktop/uEngine-project/platform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ac4c2a6')) {
      api.createRecord('4ac4c2a6', component.options)
    } else {
      api.reload('4ac4c2a6', component.options)
    }
    module.hot.accept("./Curve.vue?vue&type=template&id=4ac4c2a6&scoped=true", function () {
      api.rerender('4ac4c2a6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/opengraph/geometry/Curve.vue"
export default component.exports