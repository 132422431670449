import { VImg } from 'vuetify/lib/components/VImg';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "is-mobile",
      staticStyle: {
        width: "100px",
        height: "60px",
        "margin-top": "9px",
        cursor: "pointer",
      },
      style: _vm.tenantLogoStyle,
      attrs: { text: "" },
      on: {
        click: function ($event) {
          return _vm.goHmoe()
        },
      },
    },
    [
      _vm.tenantLogo
        ? _c(VImg, {
            attrs: {
              contain: "",
              "max-height": "90%",
              "max-width": "90%",
              src: _vm.tenantLogo,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }