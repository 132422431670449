import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.canvas ? _vm._t("default") : _vm._e(),
      _c("div", { staticClass: "canvas-wrapper" }, [
        _c("div", { staticClass: "canvas-container", attrs: { id: _vm.id } }),
      ]),
      _c(
        VCard,
        { staticStyle: { position: "relative", height: "200px" } },
        [
          _c(VCardText, {
            staticStyle: { position: "absolute" },
            attrs: { id: _vm.sliderId },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }