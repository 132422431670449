import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticStyle: { "max-width": "1500px" } },
    [
      _vm._t("body", function () {
        return [
          _c(
            "div",
            [
              _c(
                VRow,
                {
                  staticClass: "main-tap-list",
                  staticStyle: {
                    "margin-top": "-80px",
                    "margin-bottom": "10px",
                    position: "absolute",
                    "max-width": "60%",
                    "min-width": "10%",
                    "z-index": "1",
                    left: "50%",
                    transform: "translate(-50%, 0%)",
                  },
                },
                [
                  _c(
                    VTabs,
                    {
                      attrs: {
                        "background-color": "transparent",
                        color: "blue darken-1",
                        "show-arrows": "",
                        centered: "",
                      },
                      model: {
                        value: _vm.selectedIndex,
                        callback: function ($$v) {
                          _vm.selectedIndex = $$v
                        },
                        expression: "selectedIndex",
                      },
                    },
                    [
                      _vm._l(_vm.filterTabLists, function (tabObj, index) {
                        return _c(
                          "div",
                          { staticStyle: { "align-self": "center" } },
                          [
                            tabObj.show
                              ? _c(
                                  VTab,
                                  {
                                    key: tabObj.id,
                                    staticStyle: { height: "45px" },
                                    attrs: {
                                      disabled:
                                        _vm.showLoading &&
                                        index != _vm.selectedTab,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.tabClickHandler(tabObj)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(tabObj.display) +
                                        "\n                            "
                                    ),
                                    index != 0 && tabObj.totalCount != null
                                      ? _c(
                                          VAvatar,
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                              "margin-bottom": "15px",
                                              "font-size": "10px",
                                            },
                                            attrs: {
                                              color: "green lighten-5",
                                              size: "30",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(tabObj.totalCount) +
                                                "\n                            "
                                            ),
                                          ]
                                        )
                                      : index != 0 && tabObj.totalCount == null
                                      ? _c(
                                          VAvatar,
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                              "margin-bottom": "15px",
                                              "font-size": "10px",
                                            },
                                            attrs: {
                                              color: "green lighten-5",
                                              size: "30",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                ...\n                            "
                                            ),
                                          ]
                                        )
                                      : index != 0 && tabObj.totalCount == 0
                                      ? _c(
                                          VAvatar,
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                              "margin-bottom": "15px",
                                              "font-size": "10px",
                                            },
                                            attrs: {
                                              color: "green lighten-5",
                                              size: "30",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                0\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      !_vm.isOnPrem
                        ? _c(VTab, { attrs: { to: "/courses" } }, [
                            _vm._v(
                              "\n                        LEARN\n                    "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        VRow,
                        {
                          staticStyle: { width: "100%", height: "57px" },
                          attrs: { dense: "" },
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticStyle: {
                                width: "26px",
                                height: "26px",
                                "margin-top": "16px",
                                "margin-left": "15px",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.searchOpen = !_vm.searchOpen
                                },
                              },
                            },
                            [_vm._v("mdi-magnify")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.searchOpen
                ? _c(
                    VAlert,
                    {
                      staticStyle: {
                        position: "fixed",
                        top: "0px",
                        "z-index": "2",
                        height: "90px",
                        width: "40%",
                        left: "50%",
                        transform: "translate(-50%, 0%)",
                      },
                      attrs: { elevation: "2" },
                    },
                    [
                      _c(
                        VRow,
                        [
                          _vm._t("header", function () {
                            return [
                              _vm.tmpSearch
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        position: "absolute",
                                        left: "5px",
                                        top: "5px",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.filterListsCount))]
                                  )
                                : _vm._e(),
                            ]
                          }),
                          _c(
                            VBtn,
                            {
                              staticStyle: {
                                width: "auto",
                                margin: "33px 5px 0px 0px",
                                border: "0.1px solid gray",
                              },
                              attrs: { text: "", rounded: "", "x-small": "" },
                              on: {
                                mouseover: function ($event) {
                                  _vm.mouseOverOpenList = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.getSelectedMode.display) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm.mouseOverOpenList
                            ? _c(
                                VList,
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    "z-index": "1",
                                  },
                                },
                                _vm._l(_vm.listMode, function (item, index) {
                                  return _c(
                                    VListItem,
                                    {
                                      key: index,
                                      on: {
                                        click: function ($event) {
                                          return _vm.changedMode(item.type)
                                        },
                                      },
                                    },
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(_vm._s(item.display)),
                                      ]),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          _c(VTextField, {
                            staticClass: "gs-main-search",
                            attrs: { outlined: "" },
                            model: {
                              value: _vm.tmpSearch,
                              callback: function ($$v) {
                                _vm.tmpSearch = $$v
                              },
                              expression: "tmpSearch",
                            },
                          }),
                          _c(
                            VIcon,
                            {
                              staticStyle: {
                                width: "26px",
                                height: "26px",
                                "margin-top": "30px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.searchClose()
                                },
                              },
                            },
                            [_vm._v("mdi-close")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VTabsItems,
                {
                  model: {
                    value: _vm.selectedTab,
                    callback: function ($$v) {
                      _vm.selectedTab = $$v
                    },
                    expression: "selectedTab",
                  },
                },
                [
                  _vm.selectedTab == 0
                    ? _c(
                        VTabItem,
                        { attrs: { value: _vm.selectedTab } },
                        [
                          _c(
                            "div",
                            { staticClass: "clearfix gs-main-page-top-box" },
                            [
                              _c(
                                "div",
                                { staticClass: "gs-main-page-title-box" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "gs-main-page-top-title" },
                                    [
                                      _vm._v(
                                        "Microservices Architecture made easy!"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "gs-main-page-sub-title" },
                                    [
                                      _vm._v(
                                        "Frontend, Auth/Authz, GraphQL, etc. - All Patterns supported by modeling"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                                Event driven microservices with Kafka, Spring, Axon, Eventuate"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                                Container & Kubernetes Deploy Diagramming\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "gs-main-page-top-image" },
                                [
                                  _c(VImg, {
                                    attrs: {
                                      src: "/static/image/main/top_image.png",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            VCol,
                            {
                              staticClass: "gs-main-page-top-box-mobile",
                              attrs: { align: "center" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "gs-main-page-top-image-mobile",
                                },
                                [
                                  _c(VImg, {
                                    attrs: {
                                      src: "/static/image/main/top_image.png",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "gs-main-page-top-title-mobile",
                                },
                                [
                                  _vm._v(
                                    "Microservices Architecture made easy!"
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "gs-main-page-sub-title-mobile",
                                },
                                [
                                  _vm._v(
                                    "Frontend, Auth/Authz, GraphQL, etc. - All Patterns supported by modeling"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                            Event driven microservices with Kafka, Spring, Axon, Eventuate"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                            Container & Kubernetes Deploy Diagramming\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "title-page-title gs-modeling-tools-title-page-title",
                            },
                            [_vm._v(_vm._s(_vm.$t("tools.modeling")))]
                          ),
                          _c(
                            VRow,
                            { staticClass: "title-page-card-box-row" },
                            [
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "title-page-card-box",
                                      attrs: { outlined: "" },
                                    },
                                    [
                                      _c(
                                        VCardTitle,
                                        {
                                          staticClass: "home-card-title",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("tools.eventstorming")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(VImg, {
                                        staticClass: "introduction-img",
                                        attrs: {
                                          src: "/static/image/main/introduce.png",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkNewProject("es")
                                          },
                                        },
                                      }),
                                      _c(VCardSubtitle, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("tools.eventstorming-inst")
                                          )
                                        ),
                                      ]),
                                      _c(
                                        VCardActions,
                                        {
                                          staticClass: "title-card-actions-btn",
                                        },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goTutorials("es")
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.tutorial-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goVideo("es")
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.video-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                color: "#1E88E5",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "es"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.create-btn")
                                                ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "title-page-card-box",
                                      attrs: { outlined: "" },
                                    },
                                    [
                                      _c(
                                        VCardTitle,
                                        {
                                          staticClass: "home-card-title",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("tools.kubernetes"))
                                          ),
                                        ]
                                      ),
                                      _c(VImg, {
                                        staticClass: "introduction-img",
                                        attrs: {
                                          src: "/static/image/main/mainBPMN.png",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkNewProject("k8s")
                                          },
                                        },
                                      }),
                                      _c(VCardSubtitle, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("tools.kubernetes-inst")
                                          )
                                        ),
                                      ]),
                                      _c(
                                        VCardActions,
                                        {
                                          staticClass: "title-card-actions-btn",
                                        },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goTutorials("k8s")
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.tutorial-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goVideo("k8s")
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.video-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                color: "#1E88E5",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "k8s"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.create-btn")
                                                ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "title-page-card-box",
                                      attrs: { outlined: "" },
                                    },
                                    [
                                      _c(
                                        VCardTitle,
                                        {
                                          staticClass: "home-card-title",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("tools.bmc")))]
                                      ),
                                      _c(VImg, {
                                        staticClass: "introduction-img",
                                        attrs: {
                                          src: "/static/image/main/bmc.png",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkNewProject("bm")
                                          },
                                        },
                                      }),
                                      _c(VCardSubtitle, [
                                        _vm._v(
                                          _vm._s(_vm.$t("tools.bmc-inst"))
                                        ),
                                      ]),
                                      _c(
                                        VCardActions,
                                        {
                                          staticClass: "title-card-actions-btn",
                                        },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                disabled: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goTutorials("bm")
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.tutorial-btn")
                                                ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                disabled: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goVideo("bm")
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.video-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                color: "#1E88E5",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "bm"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.create-btn")
                                                ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "title-page-card-box",
                                      attrs: { outlined: "" },
                                    },
                                    [
                                      _c(
                                        VCardTitle,
                                        {
                                          staticClass: "home-card-title",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("tools.bpmn")))]
                                      ),
                                      _c(VImg, {
                                        staticClass: "introduction-img",
                                        attrs: {
                                          src: "/static/image/main/bpmn.png",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkNewProject("bpmn")
                                          },
                                        },
                                      }),
                                      _c(VCardSubtitle, [
                                        _vm._v(
                                          _vm._s(_vm.$t("tools.bpmn-inst"))
                                        ),
                                      ]),
                                      _c(
                                        VCardActions,
                                        {
                                          staticClass: "title-card-actions-btn",
                                        },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goTutorials("bpmn")
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.tutorial-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goVideo("bpmn")
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("tools.video-btn")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                color: "#1E88E5",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "bpmn"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("tools.create-btn")
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "title-page-title" }, [
                            _vm._v(_vm._s(_vm.$t("tutorials.tutorial"))),
                          ]),
                          _c(
                            VRow,
                            { staticClass: "title-page-card-box-row" },
                            [
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticStyle: {
                                            "margin-left": "3px",
                                            "margin-top": "0px",
                                            "margin-bottom": "-25px",
                                          },
                                        },
                                        [
                                          _c(
                                            VChip,
                                            {
                                              staticClass: "ma-2",
                                              staticStyle: {
                                                width: "auto",
                                                height: "20px",
                                                "font-size": "12px",
                                                "font-weight": "bold",
                                                "z-index": "200",
                                              },
                                              attrs: {
                                                color: "green",
                                                "text-color": "white",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.$t("word.dev")) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "axon"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "https://user-images.githubusercontent.com/113568664/225213375-c0dcc8cc-c696-48f0-be8d-b0330f6b9ee5.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                VListItemSubtitle,
                                                {
                                                  staticClass: "mb-1",
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                    "font-size": "14px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t("tutorials.axon")
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#757575",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "tutorials.axon-inst"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticStyle: {
                                            "margin-left": "3px",
                                            "margin-top": "0px",
                                            "margin-bottom": "-25px",
                                          },
                                        },
                                        [
                                          _c(
                                            VChip,
                                            {
                                              staticClass: "ma-2",
                                              staticStyle: {
                                                width: "auto",
                                                height: "20px",
                                                "font-size": "12px",
                                                "font-weight": "bold",
                                                "z-index": "200",
                                              },
                                              attrs: {
                                                color: "red",
                                                "text-color": "white",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.$t("word.biz")) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "ggd"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/DDD.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                VListItemSubtitle,
                                                {
                                                  staticClass: "mb-1",
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                    "font-size": "14px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t("tutorials.ddd")
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#757575",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "tutorials.ddd-inst"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticStyle: {
                                            "margin-left": "3px",
                                            "margin-top": "0px",
                                            "margin-bottom": "-25px",
                                          },
                                        },
                                        [
                                          _c(
                                            VChip,
                                            {
                                              staticClass: "ma-2",
                                              staticStyle: {
                                                width: "auto",
                                                height: "20px",
                                                "font-size": "12px",
                                                "font-weight": "bold",
                                                "z-index": "9999",
                                              },
                                              attrs: {
                                                color: "green",
                                                "text-color": "white",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.$t("word.dev")) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "run-mu"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/unit_msa.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                VListItemSubtitle,
                                                {
                                                  staticClass: "mb-1",
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                    "font-size": "14px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t("tutorials.unit")
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#757575",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "tutorials.unit-inst"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticStyle: {
                                            "margin-left": "3px",
                                            "margin-top": "0px",
                                            "margin-bottom": "-25px",
                                          },
                                        },
                                        [
                                          _c(
                                            VChip,
                                            {
                                              staticClass: "ma-2",
                                              staticStyle: {
                                                width: "auto",
                                                height: "20px",
                                                "font-size": "12px",
                                                "font-weight": "bold",
                                                "z-index": "9999",
                                              },
                                              attrs: {
                                                color: "green",
                                                "text-color": "white",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.$t("word.dev")) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "req-res"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/req_res.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                VListItemSubtitle,
                                                {
                                                  staticClass: "mb-1",
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                    "font-size": "14px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "tutorials.reqres"
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#757575",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "tutorials.reqres-inst"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticStyle: {
                                            "margin-left": "3px",
                                            "margin-top": "0px",
                                            "margin-bottom": "-25px",
                                          },
                                        },
                                        [
                                          _c(
                                            VChip,
                                            {
                                              staticClass: "ma-2",
                                              staticStyle: {
                                                width: "auto",
                                                height: "20px",
                                                "font-size": "12px",
                                                "font-weight": "bold",
                                                "z-index": "9999",
                                              },
                                              attrs: {
                                                color: "green",
                                                "text-color": "white",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.$t("word.dev")) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "cb"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/circuit_breaker.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                VListItemSubtitle,
                                                {
                                                  staticClass: "mb-1",
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                    "font-size": "14px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t("tutorials.cb")
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#757575",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "tutorials.cb-inst"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticStyle: {
                                            "margin-left": "3px",
                                            "margin-top": "0px",
                                            "margin-bottom": "-25px",
                                          },
                                        },
                                        [
                                          _c(
                                            VChip,
                                            {
                                              staticClass: "ma-2",
                                              staticStyle: {
                                                width: "auto",
                                                height: "20px",
                                                "font-size": "12px",
                                                "font-weight": "bold",
                                                "z-index": "9999",
                                              },
                                              attrs: {
                                                color: "green",
                                                "text-color": "white",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.$t("word.dev")) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "pub-sub"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/pub_sub.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                VListItemSubtitle,
                                                {
                                                  staticClass: "mb-1",
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                    "font-size": "14px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "tutorials.pubsub"
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#757575",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "tutorials.pubsub-inst"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticStyle: {
                                            "margin-left": "3px",
                                            "margin-top": "0px",
                                            "margin-bottom": "-25px",
                                          },
                                        },
                                        [
                                          _c(
                                            VChip,
                                            {
                                              staticClass: "ma-2",
                                              staticStyle: {
                                                width: "auto",
                                                height: "20px",
                                                "font-size": "12px",
                                                "font-weight": "bold",
                                                "z-index": "9999",
                                              },
                                              attrs: {
                                                color: "green",
                                                "text-color": "white",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.$t("word.dev")) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "com-cor"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/Compensation.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                VListItemSubtitle,
                                                {
                                                  staticClass: "mb-1",
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                    "font-size": "14px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "tutorials.comcor"
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#757575",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "tutorials.comcor-inst"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticStyle: {
                                            "margin-left": "3px",
                                            "margin-top": "0px",
                                            "margin-bottom": "-25px",
                                          },
                                        },
                                        [
                                          _c(
                                            VChip,
                                            {
                                              staticClass: "ma-2",
                                              staticStyle: {
                                                width: "auto",
                                                height: "20px",
                                                "font-size": "12px",
                                                "font-weight": "bold",
                                                "z-index": "9999",
                                              },
                                              attrs: {
                                                color: "green",
                                                "text-color": "white",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.$t("word.dev")) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "jwt-auth"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/jwt.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                VListItemSubtitle,
                                                {
                                                  staticClass: "mb-1",
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                    "font-size": "14px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t("tutorials.jwt")
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#757575",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "tutorials.jwt-inst"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticStyle: {
                                            "margin-left": "3px",
                                            "margin-top": "0px",
                                            "margin-bottom": "-25px",
                                          },
                                        },
                                        [
                                          _c(
                                            VChip,
                                            {
                                              staticClass: "ma-2",
                                              staticStyle: {
                                                width: "auto",
                                                height: "20px",
                                                "font-size": "12px",
                                                "font-weight": "bold",
                                                "z-index": "9999",
                                              },
                                              attrs: {
                                                color: "green",
                                                "text-color": "white",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.$t("word.dev")) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "dp-fh"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/hateoas.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                VListItemSubtitle,
                                                {
                                                  staticClass: "mb-1",
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                    "font-size": "14px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "tutorials.hateoas"
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#757575",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "tutorials.hateoas-inst"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticStyle: {
                                            "margin-left": "3px",
                                            "margin-top": "0px",
                                            "margin-bottom": "-25px",
                                          },
                                        },
                                        [
                                          _c(
                                            VChip,
                                            {
                                              staticClass: "ma-2",
                                              staticStyle: {
                                                width: "auto",
                                                height: "20px",
                                                "font-size": "12px",
                                                "font-weight": "bold",
                                                "z-index": "9999",
                                              },
                                              attrs: {
                                                color: "green",
                                                "text-color": "white",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.$t("word.dev")) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "dp-gql"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/graphql.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                VListItemSubtitle,
                                                {
                                                  staticClass: "mb-1",
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                    "font-size": "14px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "tutorials.graphql"
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#757575",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "tutorials.graphql-inst"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "title-page-title" }, [
                            _vm._v(_vm._s(_vm.$t("examples.modeling"))),
                          ]),
                          _c(
                            VRow,
                            { staticClass: "title-page-card-box-row" },
                            [
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "axonex"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "https://user-images.githubusercontent.com/113568664/224649981-78c46486-4ef0-46e6-b861-2f1a662c4c91.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                    "font-weight": "500",
                                                    "font-size": "16px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t("examples.axon")
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                VCardActions,
                                                {
                                                  staticClass:
                                                    "title-card-actions-btn",
                                                  staticStyle: {
                                                    "margin-bottom": "-15px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticStyle: {
                                                        color: "#1E88E5",
                                                        "font-weight": "850",
                                                      },
                                                      attrs: {
                                                        small: "",
                                                        depressed: "",
                                                        text: "",
                                                        to: "/storming/human-resource-mgmt-0303:v0.0.1",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "examples.enter-btn"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "petshop"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/petshop.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                    "font-weight": "500",
                                                    "font-size": "16px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t("examples.pet")
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                VCardActions,
                                                {
                                                  staticClass:
                                                    "title-card-actions-btn",
                                                  staticStyle: {
                                                    "margin-bottom": "-15px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticStyle: {
                                                        color: "#1E88E5",
                                                        "font-weight": "850",
                                                      },
                                                      attrs: {
                                                        small: "",
                                                        depressed: "",
                                                        text: "",
                                                        to: "/storming/e25a97f84aa34376697cc220496a9608",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "examples.enter-btn"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "food-delivery"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/food_delivery.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                    "font-weight": "500",
                                                    "font-size": "16px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t("examples.food")
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                VCardActions,
                                                {
                                                  staticClass:
                                                    "title-card-actions-btn",
                                                  staticStyle: {
                                                    "margin-bottom": "-15px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticStyle: {
                                                        color: "#1E88E5",
                                                        "font-weight": "850",
                                                      },
                                                      attrs: {
                                                        small: "",
                                                        depressed: "",
                                                        text: "",
                                                        to: "/storming/2737b4f61c1ea85e3de602479ddc1e3a",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "examples.enter-btn"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "shop"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/shopping.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                    "font-weight": "500",
                                                    "font-size": "16px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t("examples.shop")
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                VCardActions,
                                                {
                                                  staticClass:
                                                    "title-card-actions-btn",
                                                  staticStyle: {
                                                    "margin-bottom": "-15px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticStyle: {
                                                        color: "#1E88E5",
                                                        "font-weight": "850",
                                                      },
                                                      attrs: {
                                                        small: "",
                                                        depressed: "",
                                                        text: "",
                                                        to: "/storming/0f89dcccd80e9ec9fb6540c3236cfe2b",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "examples.enter-btn"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "k8s-blueprint"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/k8s.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                    "font-weight": "500",
                                                    "font-size": "16px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t("examples.k8s")
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                VCardActions,
                                                {
                                                  staticClass:
                                                    "title-card-actions-btn",
                                                  staticStyle: {
                                                    "margin-bottom": "-15px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticStyle: {
                                                        color: "#1E88E5",
                                                        "font-weight": "850",
                                                      },
                                                      attrs: {
                                                        small: "",
                                                        depressed: "",
                                                        text: "",
                                                        to: "/storming/e8f1d14ea6a9a714f79f73aa4fff0601",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "examples.enter-btn"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "3",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        width: "356",
                                        "min-height": "150",
                                        outlined: "",
                                      },
                                    },
                                    [
                                      _c(
                                        VListItem,
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkNewProject(
                                                    "google-drive"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VImg, {
                                                staticClass: "mt-4 mr-3",
                                                staticStyle: {
                                                  height: "115px",
                                                  width: "165px",
                                                },
                                                attrs: {
                                                  src: "/static/image/main/google_drive.png",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                    "font-weight": "500",
                                                    "font-size": "16px",
                                                    color: "black",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "examples.google"
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                VCardActions,
                                                {
                                                  staticClass:
                                                    "title-card-actions-btn",
                                                  staticStyle: {
                                                    "margin-bottom": "-15px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticStyle: {
                                                        color: "#1E88E5",
                                                        "font-weight": "850",
                                                      },
                                                      attrs: {
                                                        small: "",
                                                        depressed: "",
                                                        text: "",
                                                        to: "/storming/d8525abb1acc3cf621b6aacf371fa4be",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "examples.enter-btn"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.selectedTab < _vm.standardTabCount
                    ? _c(
                        VTabItem,
                        { attrs: { value: _vm.selectedTab } },
                        [
                          _c(
                            VRow,
                            { staticStyle: { "margin-top": "0px" } },
                            [
                              (_vm.showLoading &&
                                _vm.filteredList &&
                                _vm.filteredList.length < 10) ||
                              (_vm.filteredList == undefined &&
                                typeof _vm.filteredList == "undefined")
                                ? _c(
                                    VCol,
                                    { staticStyle: { height: "100%" } },
                                    [
                                      _c(
                                        VRow,
                                        _vm._l(9, function (idx) {
                                          return _c(
                                            VCol,
                                            { attrs: { cols: "6", md: "4" } },
                                            [
                                              _c(
                                                VCard,
                                                {
                                                  staticClass: "mx-auto",
                                                  staticStyle: {
                                                    width: "500px",
                                                    height: "400px",
                                                    "justify-content": "center",
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    align: "center",
                                                  },
                                                },
                                                [
                                                  _c(VSkeletonLoader, {
                                                    ref: "skeleton",
                                                    refInFor: true,
                                                    staticClass: "mx-auto",
                                                    attrs: { type: "card" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.filteredList == null &&
                                  typeof _vm.filteredList == "object"
                                ? _c(
                                    VCol,
                                    { staticStyle: { height: "590px" } },
                                    [
                                      _vm._v(
                                        "\n                            No Items\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._l(
                                    _vm.filteredList,
                                    function (project, index) {
                                      return _c(
                                        VCol,
                                        {
                                          key: project.projectId,
                                          attrs: {
                                            xl: "4",
                                            lg: "4",
                                            md: "6",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c(
                                            "EventStormingListCard",
                                            {
                                              attrs: { information: project },
                                              on: {
                                                delete: _vm.deleteProjectItem,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "action",
                                                    fn: function (project) {
                                                      return [
                                                        _vm._t("action", null, {
                                                          project: project,
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "chips" },
                                                [_vm._t("chips")],
                                                2
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.selectedTab != 0
                ? _c(
                    "div",
                    { staticStyle: { "text-align-last": "center" } },
                    [
                      _vm.showLoadingForMorePage
                        ? _c(
                            "div",
                            {
                              staticClass: "ticontainer",
                              staticStyle: {
                                "margin-left": "50%",
                                "margin-top": "30px",
                              },
                            },
                            [
                              _c("div", { staticClass: "tiblock" }, [
                                _c("div", { staticClass: "tidot" }),
                                _c("div", { staticClass: "tidot" }),
                                _c("div", { staticClass: "tidot" }),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.showLoading && !_vm.showMoreButton
                        ? _c(
                            "div",
                            {
                              staticStyle: { padding: "10px 0 10px 0" },
                              attrs: { block: "", text: "" },
                            },
                            [_vm._v("마지막 페이지")]
                          )
                        : _vm.showLoading && _vm.showMoreButton
                        ? _c(
                            VRow,
                            _vm._l(9, function (idx) {
                              return _c(
                                VCol,
                                { attrs: { cols: "6", md: "4" } },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "mx-auto",
                                      staticStyle: {
                                        width: "500px",
                                        height: "400px",
                                        "justify-content": "center",
                                      },
                                      attrs: { outlined: "", align: "center" },
                                    },
                                    [
                                      _c(VSkeletonLoader, {
                                        ref: "skeleton",
                                        refInFor: true,
                                        staticClass: "mx-auto",
                                        attrs: { type: "card" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      }),
      _vm.deleteItem
        ? _c(
            VDialog,
            {
              attrs: { persistent: "", "max-width": "470" },
              model: {
                value: _vm.deleteDialog,
                callback: function ($$v) {
                  _vm.deleteDialog = $$v
                },
                expression: "deleteDialog",
              },
            },
            [
              _c(
                VCard,
                [
                  _c(VCardTitle, { staticClass: "headline" }, [
                    _vm._v(_vm._s(_vm.$t("word.deleteNotification"))),
                  ]),
                  _c(VImg, { attrs: { src: _vm.deleteItem.img } }),
                  _c(VCardText, [
                    _vm._v(
                      "AuthorEmail: " +
                        _vm._s(
                          _vm.deleteItem.authorEmail
                            ? _vm.deleteItem.authorEmail.split("@")[0]
                            : "anyone"
                        ) +
                        "\n            "
                    ),
                  ]),
                  _c(VCardText, [
                    _vm._v(
                      "ProjectName: " +
                        _vm._s(
                          _vm.deleteItem.projectName
                            ? _vm.deleteItem.projectName
                            : "untitled"
                        )
                    ),
                  ]),
                  _c(VCardText, [
                    _vm._v(
                      "date: " +
                        _vm._s(_vm.convertTimeStampToDate(_vm.deleteItem.date))
                    ),
                  ]),
                  _c(VCardText, [
                    _vm._v(
                      "LastModifiedDate: " +
                        _vm._s(
                          _vm.convertTimeStampToDate(
                            _vm.deleteItem.lastModifiedDate
                          )
                        )
                    ),
                  ]),
                  _c(VCardText, [
                    _vm._v(
                      '## 알림 ## "공유"된 파일의 경우 공동작업자가 사본을 생성할 수 있습니다.'
                    ),
                  ]),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeDeleteDialog()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("word.close")))]
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "red darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteProject()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("word.delete")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }