import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.paletteMode && _vm.selectedPalette
    ? _c(
        "div",
        [
          _c(
            VRow,
            { attrs: { cols: "12" } },
            [
              _c(VCol, { attrs: { cols: "4" } }),
              _c(
                VCol,
                { attrs: { cols: "8" } },
                [
                  _c(
                    VCard,
                    {
                      staticStyle: {
                        margin: "20px 10px 20px 10px",
                        padding: "10px",
                        "max-width": "400px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        VAppBar,
                        {
                          staticStyle: { "box-shadow": "none", height: "64px" },
                          style: _vm.selectedPreviewStyle(
                            _vm.selectedPalette.colors.primary,
                            null
                          ),
                        },
                        [
                          _c(
                            VRow,
                            [
                              _c(
                                VBtn,
                                {
                                  staticStyle: { "margin-top": "8px" },
                                  attrs: { icon: "" },
                                },
                                [_c(VIcon, [_vm._v("mdi-menu")])],
                                1
                              ),
                              _c(
                                VTooltip,
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    "line-height": "64px",
                                                  },
                                                  style:
                                                    _vm.selectedPreviewStyle(
                                                      null,
                                                      _vm.selectedPalette.colors
                                                        .mainText
                                                    ),
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.generateServiceName()
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.value.serviceName)
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2090780787
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("Preview.serviceName"))
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VContainer,
                        {
                          staticStyle: { padding: "0px", "margin-top": "10px" },
                          attrs: { fluid: "" },
                        },
                        [
                          _c(
                            VRow,
                            { attrs: { dense: "" } },
                            [
                              _c(
                                VCol,
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticStyle: { padding: "10px" },
                                      style: _vm.selectedPreviewStyle(
                                        _vm.selectedPalette.colors.background,
                                        null
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex justify-center",
                                        },
                                        [
                                          _c(VImg, {
                                            staticStyle: {
                                              "max-width": "300px",
                                            },
                                            attrs: {
                                              src: _vm.selectedImage.url,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VTooltip,
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      VCardText,
                                                      _vm._g(
                                                        {
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                          },
                                                          style:
                                                            _vm.selectedPreviewStyle(
                                                              null,
                                                              _vm
                                                                .selectedPalette
                                                                .colors
                                                                .secondaryText
                                                            ),
                                                          on: {
                                                            click:
                                                              _vm.generateServiceDescription,
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.value
                                                              .serviceDescription
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2889165504
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Preview.serviceScenarios"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        VCardActions,
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              style: _vm.selectedPreviewStyle(
                                                null,
                                                _vm.selectedPalette.colors[
                                                  "primary-darken-1"
                                                ]
                                              ),
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.generateServiceDescription()
                                                },
                                              },
                                            },
                                            [_vm._v("View")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(VCol, { attrs: { cols: "4" } }),
            ],
            1
          ),
        ],
        1
      )
    : !_vm.paletteMode
    ? _c(
        "div",
        [
          _c(
            VCol,
            { staticStyle: { padding: "0" } },
            [
              _c(
                VCard,
                {
                  staticStyle: {
                    "margin-top": "10px",
                    "background-color": "#DAF5FF",
                    padding: "10px",
                    "min-width": "200px",
                    "min-height": "200px",
                  },
                },
                [
                  _c(
                    VCol,
                    [
                      _c(
                        VRow,
                        [
                          _vm._l(
                            _vm.firstPreviewPalettes,
                            function (previewColor, index) {
                              return index < 3
                                ? _c(
                                    VCol,
                                    {
                                      key: previewColor.paletteName,
                                      attrs: { cols: _vm.columnWidth },
                                    },
                                    [
                                      _c(
                                        VCard,
                                        {
                                          staticStyle: {
                                            margin: "20px 10px 20px 10px",
                                            padding: "10px",
                                            "min-width": "400px",
                                            "max-width": "400px",
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            VAppBar,
                                            {
                                              staticStyle: {
                                                "box-shadow": "none",
                                                height: "64px",
                                              },
                                              style: {
                                                background:
                                                  previewColor.colors.primary
                                                    .colorCode,
                                              },
                                            },
                                            [
                                              _c(
                                                VRow,
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "8px",
                                                      },
                                                      attrs: { icon: "" },
                                                    },
                                                    [
                                                      _c(VIcon, [
                                                        _vm._v("mdi-menu"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VTooltip,
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  _vm._g(
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                          "line-height":
                                                                            "64px",
                                                                        },
                                                                      style: {
                                                                        color:
                                                                          previewColor
                                                                            .colors
                                                                            .mainText
                                                                            .colorCode,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.generateServiceName()
                                                                          },
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .value
                                                                          .serviceName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Preview.serviceName"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VContainer,
                                            {
                                              staticStyle: {
                                                padding: "0px",
                                                "margin-top": "10px",
                                              },
                                              attrs: { fluid: "" },
                                            },
                                            [
                                              _c(
                                                VRow,
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    VCol,
                                                    [
                                                      _c(
                                                        VCard,
                                                        {
                                                          staticStyle: {
                                                            padding: "10px",
                                                          },
                                                          style: {
                                                            background:
                                                              previewColor
                                                                .colors
                                                                .background
                                                                .colorCode,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-center",
                                                            },
                                                            [
                                                              _c(VImg, {
                                                                staticStyle: {
                                                                  "max-width":
                                                                    "300px",
                                                                },
                                                                attrs: {
                                                                  src: _vm
                                                                    .selectedImage
                                                                    .url,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            VTooltip,
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            VCardText,
                                                                            _vm._g(
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  },
                                                                                style:
                                                                                  {
                                                                                    color:
                                                                                      previewColor
                                                                                        .colors
                                                                                        .secondaryText
                                                                                        .colorCode,
                                                                                  },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .value
                                                                                    .serviceDescription
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Preview.serviceScenarios"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            VCardActions,
                                                            [
                                                              _c(VSpacer),
                                                              _c(
                                                                VBtn,
                                                                {
                                                                  style: {
                                                                    background:
                                                                      previewColor
                                                                        .colors[
                                                                        "primary-darken-1"
                                                                      ]
                                                                        .colorCode,
                                                                  },
                                                                  attrs: {
                                                                    text: "",
                                                                  },
                                                                },
                                                                [_vm._v("View")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass: "ms-2",
                                                  staticStyle: {
                                                    top: "6px",
                                                    left: "36%",
                                                  },
                                                  attrs: {
                                                    variant: "outlined",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changePalette(
                                                        previewColor
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    PALETTE\n                                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e()
                            }
                          ),
                          _vm._l(
                            _vm.firstPreviewPalettes,
                            function (previewColor, index) {
                              return index >= 3
                                ? _c(
                                    VCol,
                                    {
                                      key: previewColor.paletteName,
                                      attrs: { cols: _vm.columnWidth },
                                    },
                                    [
                                      _c(
                                        VCard,
                                        {
                                          staticStyle: {
                                            margin: "20px 10px 20px 10px",
                                            padding: "10px",
                                            "max-width": "400px",
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            VAppBar,
                                            {
                                              staticStyle: {
                                                "box-shadow": "none",
                                                height: "64px",
                                              },
                                              style: {
                                                background:
                                                  previewColor.colors.primary
                                                    .colorCode,
                                              },
                                            },
                                            [
                                              _c(
                                                VRow,
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "8px",
                                                      },
                                                      attrs: { icon: "" },
                                                    },
                                                    [
                                                      _c(VIcon, [
                                                        _vm._v("mdi-menu"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VTooltip,
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  _vm._g(
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                          "line-height":
                                                                            "64px",
                                                                        },
                                                                      style: {
                                                                        color:
                                                                          previewColor
                                                                            .colors
                                                                            .mainText
                                                                            .colorCode,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.generateServiceName()
                                                                          },
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .value
                                                                          .serviceName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Preview.serviceName"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VContainer,
                                            {
                                              staticStyle: {
                                                padding: "0px",
                                                "margin-top": "10px",
                                              },
                                              attrs: { fluid: "" },
                                            },
                                            [
                                              _c(
                                                VRow,
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    VCol,
                                                    [
                                                      _c(
                                                        VCard,
                                                        {
                                                          staticStyle: {
                                                            padding: "10px",
                                                          },
                                                          style: {
                                                            background:
                                                              previewColor
                                                                .colors
                                                                .background
                                                                .colorCode,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-center",
                                                            },
                                                            [
                                                              _c(VImg, {
                                                                staticStyle: {
                                                                  "max-width":
                                                                    "300px",
                                                                },
                                                                attrs: {
                                                                  src: _vm
                                                                    .selectedImage
                                                                    .url,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            VTooltip,
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            VCardText,
                                                                            _vm._g(
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  },
                                                                                style:
                                                                                  {
                                                                                    color:
                                                                                      previewColor
                                                                                        .colors
                                                                                        .secondaryText
                                                                                        .colorCode,
                                                                                  },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .value
                                                                                    .serviceDescription
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Preview.serviceScenarios"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            VCardActions,
                                                            [
                                                              _c(VSpacer),
                                                              _c(
                                                                VBtn,
                                                                {
                                                                  style: {
                                                                    background:
                                                                      previewColor
                                                                        .colors[
                                                                        "primary-darken-1"
                                                                      ]
                                                                        .colorCode,
                                                                  },
                                                                  attrs: {
                                                                    text: "",
                                                                  },
                                                                },
                                                                [_vm._v("View")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass: "ms-2",
                                                  staticStyle: {
                                                    top: "6px",
                                                    left: "36%",
                                                  },
                                                  attrs: {
                                                    variant: "outlined",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changePalette(
                                                        previewColor
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    PALETTE\n                                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e()
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }