import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    [
      _c(
        VRow,
        { attrs: { justify: "end", align: "center", id: "participant-lists" } },
        [
          _vm._l(_vm.participantLists, function (participant, index) {
            return _c(
              "div",
              { staticStyle: { width: "30px" } },
              [
                _c(
                  VTooltip,
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _vm.participantLists.length - 8 == index
                                ? _c(
                                    VAvatar,
                                    { attrs: { size: "40" } },
                                    [
                                      _c(VIcon, [
                                        _vm._v(" mdi-account-multiple"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.participantLists.length - 7 <= index &&
                              index < _vm.participantLists.length
                                ? _c(
                                    VAvatar,
                                    _vm._g(
                                      {
                                        style:
                                          _vm.participantLists.length > 1
                                            ? "display:block;"
                                            : "display:none;",
                                        attrs: { size: "36" },
                                      },
                                      on
                                    ),
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: participant.picture,
                                          alt: participant.userName,
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(participant.userName) +
                          " " +
                          _vm._s(participant.email)
                      ),
                    ]),
                  ]
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticStyle: { "margin-left": "10px" } },
            [
              _c(
                VTooltip,
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            VCard,
                            _vm._g(
                              {
                                staticStyle: {
                                  width: "62px",
                                  padding: "5px",
                                  "margin-right": "15px",
                                  "font-size": "14px",
                                },
                                style:
                                  _vm.participantLists.length >= 6
                                    ? "display:block;"
                                    : "display:none;",
                                attrs: { color: "indigo", outlined: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openPanel()
                                  },
                                },
                              },
                              on
                            ),
                            [
                              _c(VIcon, { attrs: { size: "20" } }, [
                                _vm._v(" mdi-account-multiple"),
                              ]),
                              _vm._v(
                                "\n                    공유\n                    "
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Participant Lists")])]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }