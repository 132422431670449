import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VNavigationDrawer,
    {
      attrs: { app: "", clipped: "", stateless: "" },
      model: {
        value: _vm.value.drawer,
        callback: function ($$v) {
          _vm.$set(_vm.value, "drawer", $$v)
        },
        expression: "value.drawer",
      },
    },
    [
      _c(
        VList,
        { attrs: { dense: "" } },
        [
          _c(VSubheader, [_vm._v("\n            Instruction\n        ")]),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.compiledMarkdown) } }),
        ],
        1
      ),
      _c(VDivider),
      _c(
        VList,
        { attrs: { dense: "" } },
        [
          _c(VSubheader, [_vm._v("\n            checkPoints\n        ")]),
          _vm._l(_vm.value.checkPoints, function (item, idx) {
            return _c(
              VListItem,
              { key: idx },
              [
                _c(
                  VListItemContent,
                  [
                    _c(VListItemTitle, [
                      _vm._v(_vm._s(idx + 1) + ". " + _vm._s(item.text)),
                    ]),
                  ],
                  1
                ),
                _c(
                  VListItemAction,
                  [
                    _vm.renderComponent && _vm.isAdmin
                      ? _c(VCheckbox, {
                          attrs: { success: "" },
                          model: {
                            value: item.status,
                            callback: function ($$v) {
                              _vm.$set(item, "status", $$v)
                            },
                            expression: "item.status",
                          },
                        })
                      : _vm._e(),
                    _vm.renderComponent &&
                    (item.javascript || item.regExp) &&
                    !_vm.isAdmin
                      ? _c(VCheckbox, {
                          attrs: { success: "", readonly: "" },
                          model: {
                            value: item.status,
                            callback: function ($$v) {
                              _vm.$set(item, "status", $$v)
                            },
                            expression: "item.status",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(VDivider),
      _c(
        VList,
        { attrs: { dense: "", shaped: "" } },
        [
          _c(VSubheader, [_vm._v("\n            hints\n        ")]),
          _vm._l(_vm.value.hints, function (hint, idx) {
            return _c(
              VListItem,
              { attrs: { "three-line": "" } },
              [
                _c(
                  VListItemContent,
                  [
                    _c(VListItemTitle, [
                      _vm._v(_vm._s(idx + 1) + ". " + _vm._s(hint.text)),
                    ]),
                  ],
                  1
                ),
                _c(
                  VListItemAction,
                  [
                    _vm.renderComponent
                      ? _c(VCheckbox, {
                          attrs: { error: "", readonly: "" },
                          model: {
                            value: hint.status,
                            callback: function ($$v) {
                              _vm.$set(hint, "status", $$v)
                            },
                            expression: "hint.status",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }