import { VCol } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCol,
    {
      staticStyle: {
        height: "100%",
        "align-items": "center",
        margin: "2px",
        width: "100%",
      },
    },
    _vm._l(_vm.value, function (id) {
      return _c(
        "div",
        { key: id },
        [
          _c("jump-to-model-lists-card", {
            attrs: { id: id, url: _vm.url },
            on: { deleteModel: _vm.deleteModel },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }