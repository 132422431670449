import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VAlert,
        {
          staticClass: "pa-2 mt-1 mb-4",
          attrs: { color: "#757575", variant: "tonal" },
        },
        [
          _c(
            VRow,
            { staticClass: "ma-0 pa-0", attrs: { align: "center" } },
            [
              _c(VIcon, { staticClass: "mr-2", attrs: { color: "black" } }, [
                _vm._v("mdi-information"),
              ]),
              _c(
                VCardTitle,
                { staticClass: "pa-0", staticStyle: { flex: "1" } },
                [
                  _vm._v(
                    "\n                " + _vm._s(_vm.title) + "\n            "
                  ),
                ]
              ),
              _vm.details
                ? _c(VTooltip, {
                    attrs: { text: _vm.$t("details") },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ props }) {
                            return [
                              _c(
                                VIcon,
                                _vm._b(
                                  {
                                    attrs: { color: "black" },
                                    on: {
                                      click: function ($event) {
                                        _vm.extendedStatus = !_vm.extendedStatus
                                      },
                                    },
                                  },
                                  "v-icon",
                                  props,
                                  false
                                ),
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.extendedStatus
                                          ? "mdi-chevron-up"
                                          : "mdi-chevron-down"
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1539204322
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.extendedStatus
            ? _c(
                "div",
                { staticClass: "pt-1" },
                _vm._l(_vm.details, function (item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        item.title
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "16px",
                                  "white-space": "pre-wrap",
                                  margin: "20px 0px 5px 0px",
                                },
                              },
                              [_vm._v(_vm._s(item.title))]
                            )
                          : _vm._e(),
                        item.image
                          ? _c(VImg, {
                              staticStyle: {
                                border: "solid 1px gray",
                                "border-radius": "8px",
                              },
                              attrs: { src: _vm.getImagePath(item.image) },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }