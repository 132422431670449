import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VBtn,
        {
          attrs: { block: "", small: "" },
          on: {
            click: function ($event) {
              return _vm.signInGitlab()
            },
          },
        },
        [
          _c("img", {
            attrs: {
              id: "git-hover",
              width: "30px",
              alt: "Github sign-in",
              src: "/static/image/gitlab-logo-500.png",
            },
          }),
          _c("div", {}, [_vm._v("sign in with gitlab")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }