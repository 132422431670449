import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isGitMenu
    ? _c(
        "div",
        [
          _c(
            VBtn,
            {
              staticStyle: { "background-color": "white" },
              on: {
                click: function ($event) {
                  return _vm.snsLogin("github")
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  id: "git-hover",
                  width: "30px",
                  alt: "Github sign-in",
                  src: "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
                },
              }),
              _c("div", [_vm._v("sign in with github")]),
            ]
          ),
        ],
        1
      )
    : _c(
        "div",
        [
          _vm.standard
            ? _c(
                VCard,
                { staticClass: "login-dialog-is-pc" },
                [
                  _c(
                    VRow,
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c(
                        VCol,
                        {
                          staticClass:
                            "ma-0 pa-0 d-flex flex-column align-center justify-center",
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: "/static/image/login/login-web.png" },
                          }),
                        ]
                      ),
                      _c(
                        VCol,
                        {
                          staticClass: "ma-0",
                          staticStyle: { padding: "20px 10px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "40px",
                                "font-weight": "700",
                                "text-align": "center",
                                padding: "15px",
                              },
                            },
                            [_vm._v("Welcome to MSAEZ")]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "margin-bottom": "25px",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("loginList.LoginInformation1"))
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("loginList.LoginInformation2"))
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "login-btn git-login-btn",
                                  staticStyle: {
                                    "margin-right": "20px",
                                    "margin-top": "0px",
                                  },
                                  attrs: { color: "black" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.snsLogin("github")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    VCol,
                                    [
                                      _c("Icon", {
                                        staticClass: "cp-github-login",
                                        staticStyle: { color: "white" },
                                        attrs: {
                                          icon: "fa6-brands:github",
                                          width: "40",
                                          height: "40",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "white",
                                            "white-space": "normal",
                                            "word-wrap": "break-word",
                                            "text-align": "center",
                                            "margin-top": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Continue"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                                With"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                                Github\n                            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.onlyGitLogin
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "login-btn",
                                      staticStyle: { "margin-top": "0px" },
                                      attrs: { color: "white" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.snsLogin("google")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        VCol,
                                        [
                                          _c("Icon", {
                                            attrs: {
                                              icon: "flat-color-icons:google",
                                              width: "40",
                                              height: "40",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "white-space": "normal",
                                                "word-wrap": "break-word",
                                                "text-align": "center",
                                                "margin-top": "5px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Continue"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                                With"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                                Google\n                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "text-center login-message-box" },
                            [
                              _c(
                                "div",
                                { staticClass: "login-sub-message-box" },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.$t("loginList.cookieCheck1"))
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("loginList.cookieCheck2"))
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.standard
            ? _c(
                VCard,
                { staticClass: "login-dialog-is-mobile" },
                [
                  _c(
                    VRow,
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c(
                        VCol,
                        {
                          staticClass: "ma-0",
                          staticStyle: { padding: "20px 10px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "28px",
                                "font-weight": "700",
                                "text-align": "center",
                                padding: "15px",
                              },
                            },
                            [_vm._v("Welcome to MSAEZ")]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "font-size": "13px",
                                "margin-bottom": "25px",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("loginList.LoginInformation1"))
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("loginList.LoginInformation2"))
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "login-btn git-login-btn",
                                  staticStyle: {
                                    "margin-right": "20px",
                                    "margin-top": "0px",
                                  },
                                  attrs: { color: "black" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.snsLogin("github")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    VCol,
                                    [
                                      _c("Icon", {
                                        staticStyle: { color: "white" },
                                        attrs: {
                                          icon: "fa6-brands:github",
                                          width: "40",
                                          height: "40",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "white",
                                            "white-space": "normal",
                                            "word-wrap": "break-word",
                                            "text-align": "center",
                                            "margin-top": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Continue"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                                With"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                                Github\n                            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.onlyGitLogin
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "login-btn",
                                      staticStyle: { "margin-top": "0px" },
                                      attrs: { color: "white" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.snsLogin("google")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        VCol,
                                        [
                                          _c("Icon", {
                                            attrs: {
                                              icon: "flat-color-icons:google",
                                              width: "40",
                                              height: "40",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "white-space": "normal",
                                                "word-wrap": "break-word",
                                                "text-align": "center",
                                                "margin-top": "5px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Continue"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                                With"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                                Google\n                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "text-center login-message-box" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "login-sub-message-box",
                                  staticStyle: { "font-size": "12px" },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.$t("loginList.cookieCheck1"))
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("loginList.cookieCheck2"))
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.guest
            ? _c(
                VCard,
                {
                  staticClass: "elevation-12",
                  staticStyle: {
                    background: "#FFFFFF",
                    "min-width": "500px",
                    "max-width": "500px",
                    position: "absolute",
                    display: "block",
                    left: "50%",
                    top: "50%",
                    margin: "-200px 0 0 -250px",
                  },
                },
                [
                  _c(VCard, { attrs: { flat: "" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              margin: "20px",
                              "text-align": "center",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("loginList.LoginInformation1"))
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("loginList.LoginInformation2"))
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          VBtn,
                          {
                            staticStyle: {
                              "background-color": "white",
                              width: "400px",
                              "margin-left": "50px",
                              height: "50px",
                              "margin-bottom": "10px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.snsLogin("github")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                id: "git-hover",
                                width: "50px",
                                alt: "Github sign-in",
                                src: "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
                              },
                            }),
                            _c("div", [_vm._v("Github")]),
                          ]
                        ),
                        !_vm.onlyGitLogin
                          ? _c(
                              VBtn,
                              {
                                staticStyle: {
                                  "background-color": "white",
                                  width: "400px",
                                  "margin-left": "50px",
                                  height: "50px",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.snsLogin("google")
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    width: "40px",
                                    alt: "Google sign-in",
                                    src: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png",
                                  },
                                }),
                                _c("div", [_vm._v("Google")]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "500px",
                          "margin-left": "5px",
                          "margin-bottom": "20px",
                          "text-align": "center",
                          "font-size": "small",
                          color: "#BDBDBD",
                          "margin-top": "30px",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("loginList.cookieCheck1"))
                        ),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("loginList.cookieCheck2")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm.onlyConnectionKey
            ? _c(
                VCard,
                {
                  staticStyle: {
                    background: "#FFFFFF",
                    "min-width": "400px",
                    position: "absolute",
                    top: "150px",
                    left: "50%",
                    "margin-left": "-200px",
                  },
                },
                [
                  _c(
                    VCard,
                    [
                      _c(VCardTitle, [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v("접속키 입력"),
                        ]),
                      ]),
                      _c(
                        VCardText,
                        [
                          _vm.isConnectionkey
                            ? _c(VTextField, {
                                attrs: { label: "접속 키", required: "" },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.inputConnectionKey()
                                  },
                                },
                                model: {
                                  value: _vm.connectionKey,
                                  callback: function ($$v) {
                                    _vm.connectionKey = $$v
                                  },
                                  expression: "connectionKey",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        VCardActions,
                        { staticStyle: { "margin-left": "250px" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { color: "blue darken-1", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeLogin()
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                          _c(
                            VBtn,
                            {
                              attrs: { color: "blue darken-1", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.inputConnectionKey()
                                },
                              },
                            },
                            [_vm._v("입력")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }