var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("RefundItemTemplate", {
        attrs: {
          title: _vm.title,
          itemAmount: _vm.paidAmount,
          issuedDate: _vm.getIssuedDate,
          expiredDate: _vm.getExpiredDate,
          thumbnailText: _vm.thumbnailText,
          thumbnailImg: _vm.thumbnailImg,
          userEmail: _vm.getUserEmail,
          resourceType: _vm.resourceType,
          paymentType: _vm.paymentType,
          refunding: _vm.refunding,
          refundReasons: _vm.refundReasons,
        },
        on: { close: _vm.closeDialog, submit: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }