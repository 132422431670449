import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.standard
    ? _c(
        VCard,
        {
          staticClass: "elevation-12",
          staticStyle: {
            background: "#FFFFFF",
            "min-width": "300px",
            "max-width": "300px",
            height: "130px",
            position: "absolute",
            display: "block",
            left: "50%",
            top: "50%",
            margin: "-175px 0 0 -150px",
          },
        },
        [
          _c(
            VBtn,
            {
              attrs: { block: "", small: "", height: "80px" },
              on: {
                click: function ($event) {
                  return _vm.signInAcebase()
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  id: "git-hover",
                  width: "30px",
                  "margin-right": "10px",
                  alt: "Github sign-in",
                  src: "/static/image/" + _vm.provider + ".png",
                },
              }),
              _c("div", {}, [_vm._v("sign in with " + _vm._s(_vm.provider))]),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "290px",
                "margin-left": "5px",
                "text-align": "center",
                "font-size": "small",
                color: "#BDBDBD",
                "margin-top": "5px",
              },
            },
            [
              _vm._v(
                "\n        Please ensure 3rd party cookies are enabled if"
              ),
              _c("br"),
              _vm._v(" login fails.\n    "),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }