import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "margin-top": "10px" } }, [
    _c(
      "div",
      [
        _vm.value && _vm.value.modelList
          ? _c(
              VBtn,
              {
                staticClass: "auto-modeling-btn",
                attrs: { color: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.jump()
                  },
                },
              },
              [
                _vm._v("Create Model"),
                _c(VIcon, { staticClass: "auto-modeling-btn-icon" }, [
                  _vm._v("mdi-arrow-right"),
                ]),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm.value && _vm.value.modelList && _vm.value.modelList.length > 0
      ? _c("div", {
          staticClass: "auto-modeling-message-card",
          staticStyle: { "margin-top": "25px", height: "100%", width: "20%" },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }