import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandXTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showChat
    ? _c(
        "div",
        [
          _vm.autoModelingInput
            ? _c(
                VCard,
                {
                  staticClass: "mx-auto",
                  staticStyle: {
                    "max-width": "70%",
                    "background-color": "aliceblue",
                  },
                },
                [
                  _c(
                    VRow,
                    {
                      staticClass: "justify-start main-auto-modeling-chip-row",
                    },
                    [
                      _c(
                        VCol,
                        {
                          staticClass: "text-left",
                          staticStyle: { padding: "0px" },
                        },
                        _vm._l(
                          _vm.setAutoModelingTextChips,
                          function (inputAutoModelingChip, index) {
                            return _c(
                              VChip,
                              {
                                key: index,
                                staticClass: "main-auto-modeling-chip",
                                attrs: { outlined: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.setAutoModelingText(
                                      _vm.$t(inputAutoModelingChip)
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t(inputAutoModelingChip)) +
                                    "\n                "
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VCardText,
                    { staticStyle: { "font-weight": "500" } },
                    [
                      _c(VTextField, {
                        staticClass: "auto-modeling-text",
                        staticStyle: { "margin-bottom": "-30px" },
                        attrs: {
                          solo: "",
                          placeholder: _vm.$t("autoModeling.mainClick"),
                          label: _vm.$t("autoModeling.main1"),
                          "append-icon": _vm.startTemplateGenerate
                            ? "mdi-spin mdi-loading"
                            : "mdi-auto-fix",
                        },
                        on: {
                          "click:append": function ($event) {
                            return _vm.openProjectDialog()
                          },
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.openProjectDialog()
                          },
                        },
                        model: {
                          value: _vm.projectInfo.prompt,
                          callback: function ($$v) {
                            _vm.$set(_vm.projectInfo, "prompt", $$v)
                          },
                          expression: "projectInfo.prompt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            VCol,
            {
              staticClass: "shrink",
              style: _vm.openChatUI ? "height:10000px;" : "",
            },
            [
              _c(
                VExpandXTransition,
                [
                  _c(
                    VCard,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.openChatUI,
                          expression: "openChatUI",
                        },
                      ],
                      staticClass: "mx-auto bg-secondary",
                      staticStyle: {
                        height: "100%",
                        position: "absolute",
                        width: "100%",
                        "background-color": "aliceblue",
                        left: "0px",
                        top: "0px",
                        "z-index": "201",
                      },
                    },
                    [
                      _c(
                        VCardText,
                        [
                          _c(
                            VRow,
                            {
                              staticStyle: {
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c(
                                VIcon,
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelCreateModel()
                                    },
                                  },
                                },
                                [_vm._v("mdi-arrow-left")]
                              ),
                              _c(
                                "div",
                                [
                                  !_vm.isServer
                                    ? _c(
                                        VIcon,
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.openStorageDialog()
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-content-save")]
                                      )
                                    : _vm._e(),
                                  _vm.isServer
                                    ? _c(
                                        VIcon,
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.generatePowerPoint()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "mdi-file-powerpoint-box-outline"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(VTextField, {
                            staticStyle: {
                              "margin-bottom": "-30px",
                              padding: "10px",
                              width: "80%",
                              float: "right",
                              "margin-top": "10px",
                            },
                            style:
                              !_vm.openAiResult && _vm.openAiResult == ""
                                ? "margin-top: 15px;"
                                : "",
                            attrs: {
                              solo: "",
                              hint: _vm.$t("autoModeling.mainClick"),
                              "persistent-hint": "",
                              label: _vm.$t("autoModeling.main2"),
                              "append-icon": _vm.startTemplateGenerate
                                ? "mdi-spin mdi-loading"
                                : "mdi-auto-fix",
                            },
                            on: {
                              "click:append": function ($event) {
                                return _vm.startGen(_vm.genType)
                              },
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.startGen(_vm.genType)
                              },
                            },
                            model: {
                              value: _vm.projectInfo.prompt,
                              callback: function ($$v) {
                                _vm.$set(_vm.projectInfo, "prompt", $$v)
                              },
                              expression: "projectInfo.prompt",
                            },
                          }),
                          _vm.openChatUI
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    "margin-top": "100px",
                                  },
                                },
                                [
                                  _c(
                                    VCol,
                                    { staticStyle: { padding: "0px" } },
                                    [
                                      _c(
                                        VCard,
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            "background-color": "#DAF5FF",
                                          },
                                        },
                                        [
                                          _c(
                                            VCardText,
                                            {
                                              staticClass:
                                                "auto-modeling-message",
                                            },
                                            [
                                              _c(
                                                "vue-typed-js",
                                                {
                                                  attrs: {
                                                    strings: [
                                                      _vm.$t(
                                                        "autoModeling.selectOptions"
                                                      ),
                                                    ],
                                                    typeSpeed: 5,
                                                    showCursor: false,
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass: "typing",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCard,
                                    {
                                      staticStyle: {
                                        "margin-top": "10px",
                                        display: "inline-block",
                                        "background-color": "#DAF5FF",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticStyle: { padding: "10px" },
                                          attrs: {
                                            lg: "3",
                                            md: "3",
                                            sm: "6",
                                            cols: "12",
                                          },
                                        },
                                        _vm._l(
                                          _vm.cardItems,
                                          function (item, index) {
                                            return _c(
                                              VCol,
                                              {
                                                key: index,
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  VCard,
                                                  {
                                                    class: item.class,
                                                    style:
                                                      _vm.genType == item.type
                                                        ? "border: solid darkturquoise;"
                                                        : "background-color: white;",
                                                  },
                                                  [
                                                    _c(
                                                      VChip,
                                                      {
                                                        staticStyle: {
                                                          position: "absolute",
                                                          right: "5px",
                                                          top: "5px",
                                                          "z-index": "1",
                                                        },
                                                        style:
                                                          item.chipText ==
                                                          "Stable"
                                                            ? "color: white;"
                                                            : "",
                                                        attrs: {
                                                          "x-small": "",
                                                          outlined:
                                                            item.chipOutlined,
                                                          color: item.chipColor,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.chipText)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.checkLogin(
                                                              item.type
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          VAvatar,
                                                          {
                                                            staticClass: "ma-3",
                                                            attrs: {
                                                              size: "125",
                                                              rounded: "0",
                                                            },
                                                          },
                                                          [
                                                            _c(VImg, {
                                                              attrs: {
                                                                src: item.imgSrc,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          VCardText,
                                                          {
                                                            staticStyle: {
                                                              "justify-content":
                                                                "center",
                                                              "margin-top":
                                                                "-10px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "font-weight":
                                                                    "500",
                                                                  "font-size":
                                                                    "12px",
                                                                  "margin-left":
                                                                    "-5px",
                                                                  "border-radius":
                                                                    "10px",
                                                                  "margin-right":
                                                                    "-10px",
                                                                },
                                                                style:
                                                                  _vm.genType ==
                                                                  item.type
                                                                    ? "background-color: #DAF5FF;"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm.genType ==
                                                                item.type
                                                                  ? _c(
                                                                      VIcon,
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "success",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-check"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(
                                                                  "\n                                                        " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        item.textKey
                                                                      )
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.startCrateModel && _vm.genType == "BM"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-top": "10px",
                                            "margin-left": "5px",
                                          },
                                        },
                                        [
                                          _c(VProgressCircular, {
                                            attrs: {
                                              indeterminate: "",
                                              color: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { key: _vm.reGenKey },
                            [
                              _vm.genType == "ES2"
                                ? _c("ESDialoger", {
                                    ref: "esDialoger",
                                    attrs: {
                                      isServerProject: _vm.isServer,
                                      projectId: _vm.projectId,
                                      modelIds: _vm.modelIds,
                                      prompt: _vm.projectInfo.prompt,
                                      cachedModels: _vm.cachedModels,
                                      uiStyle: _vm.uiStyle,
                                    },
                                    on: { change: _vm.backupProject },
                                    model: {
                                      value: _vm.projectInfo.eventStorming,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.projectInfo,
                                          "eventStorming",
                                          $$v
                                        )
                                      },
                                      expression: "projectInfo.eventStorming",
                                    },
                                  })
                                : _vm._e(),
                              _vm.genType == "CJM"
                                ? _c("CJMDialoger", {
                                    ref: "cjMDialoger",
                                    attrs: {
                                      isServerProject: _vm.isServer,
                                      projectId: _vm.projectId,
                                      modelIds: _vm.modelIds,
                                      prompt: _vm.projectInfo.prompt,
                                      cachedModels: _vm.cachedModels,
                                    },
                                    on: {
                                      change: _vm.backupProject,
                                      setPersonas: _vm.setPersonas,
                                    },
                                    model: {
                                      value: _vm.projectInfo.customerJourneyMap,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.projectInfo,
                                          "customerJourneyMap",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "projectInfo.customerJourneyMap",
                                    },
                                  })
                                : _vm._e(),
                              _vm.genType == "BM2"
                                ? _c("BMDialoger", {
                                    ref: "bmDialoger",
                                    attrs: {
                                      isServerProject: _vm.isServer,
                                      projectId: _vm.projectId,
                                      modelIds: _vm.modelIds,
                                      prompt: _vm.projectInfo.prompt,
                                      cachedModels: _vm.cachedModels,
                                    },
                                    on: { change: _vm.backupProject },
                                    model: {
                                      value: _vm.projectInfo.businessModel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.projectInfo,
                                          "businessModel",
                                          $$v
                                        )
                                      },
                                      expression: "projectInfo.businessModel",
                                    },
                                  })
                                : _vm._e(),
                              _vm.genType == "USM"
                                ? _c("USMDialoger", {
                                    ref: "usmDialoger",
                                    attrs: {
                                      isServerProject: _vm.isServer,
                                      projectId: _vm.projectId,
                                      modelIds: _vm.modelIds,
                                      prompt: _vm.projectInfo.prompt,
                                      cachedModels: _vm.cachedModels,
                                    },
                                    on: { change: _vm.backupProject },
                                    model: {
                                      value: _vm.projectInfo.userStoryMap,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.projectInfo,
                                          "userStoryMap",
                                          $$v
                                        )
                                      },
                                      expression: "projectInfo.userStoryMap",
                                    },
                                  })
                                : _vm._e(),
                              _vm.genType == "UI"
                                ? _c("UIWizardDialoger", {
                                    ref: "uiDialoger",
                                    attrs: {
                                      isServerProject: _vm.isServer,
                                      projectId: _vm.projectId,
                                      modelIds: _vm.modelIds,
                                      prompt: _vm.projectInfo.prompt,
                                      cachedModels: _vm.cachedModels,
                                    },
                                    on: {
                                      change: _vm.backupProject,
                                      selected: _vm.onUIStyleSelected,
                                    },
                                    model: {
                                      value: _vm.projectInfo.ui,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.projectInfo, "ui", $$v)
                                      },
                                      expression: "projectInfo.ui",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("ModelStorageDialog", {
                attrs: {
                  showDialog: _vm.showStorageDialog,
                  condition: _vm.storageCondition,
                },
                on: {
                  save: _vm.saveProject,
                  close: function ($event) {
                    return _vm.closeStorageDialog()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            VSnackbar,
            {
              staticStyle: { "z-index": "100000" },
              attrs: {
                outlined: "",
                color: _vm.snackbar.color,
                "multi-line": _vm.snackbar.multi,
                timeout: _vm.snackbar.timeout,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "action",
                    fn: function ({ attrs }) {
                      return [
                        _c(
                          VBtn,
                          _vm._b(
                            {
                              attrs: { small: "", icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.snackbar.show = false
                                },
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          [_c(VIcon, [_vm._v("mdi-close")])],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3636813500
              ),
              model: {
                value: _vm.snackbar.show,
                callback: function ($$v) {
                  _vm.$set(_vm.snackbar, "show", $$v)
                },
                expression: "snackbar.show",
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.snackbar.text) + "\n        ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }