import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        background: "white",
        height: "100%",
        width: "100%",
        "padding-bottom": "20px",
        "overflow-y": "hidden",
        "overflow-x": "hidden",
      },
    },
    [
      _vm.choice == 0
        ? _c(
            "div",
            [
              _c(
                VBtn,
                {
                  staticStyle: { float: "right", right: "-10px" },
                  attrs: { small: "", text: "" },
                  on: { click: _vm.close },
                },
                [_c(VIcon, { attrs: { small: "" } }, [_vm._v("mdi-close")])],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "main-title",
                  staticStyle: { "margin-left": "38%", "padding-top": "10px" },
                },
                [_vm._v("수단 선택")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "padding-left": "8%",
                    "margin-top": "20px",
                  },
                },
                [
                  _c(VRow, { staticStyle: { "margin-bottom": "1%" } }, [
                    _c("div", { staticClass: "content-title" }, [
                      _vm._v("결제 상품 :"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "content-text",
                        staticStyle: { margin: "4px 0 0 5px" },
                      },
                      [_vm._v(_vm._s(_vm.getTitle))]
                    ),
                  ]),
                  _c(VRow, { staticStyle: { "margin-bottom": "1%" } }, [
                    _c("div", { staticClass: "content-title" }, [
                      _vm._v("사용 기간 :"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "content-text",
                        staticStyle: { margin: "3px 0 0 5px" },
                      },
                      [_vm._v(_vm._s(_vm.getPeriodDate))]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { width: "92%", "margin-left": "4%" } },
                [_vm._t("default")],
                2
              ),
              _c(
                VCard,
                {
                  staticClass: "choice-card",
                  staticStyle: {
                    width: "100%",
                    "margin-bottom": "10px",
                    display: "block",
                    "text-aling": "center",
                  },
                  attrs: { outlined: "", flat: "" },
                },
                [
                  _c(
                    VCardText,
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCard,
                            {
                              attrs: {
                                width: "50%",
                                height: "200px;",
                                disabled: _vm.onlyPG,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectPaymentType(1)
                                },
                              },
                            },
                            [
                              _c(
                                VCardTitle,
                                {
                                  staticStyle: { "justify-content": "center" },
                                },
                                [_vm._v("코인")]
                              ),
                              _c(
                                VCardText,
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("Icon", {
                                    attrs: {
                                      icon: "ph:coins-thin",
                                      width: "70",
                                      height: "70",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            VCard,
                            {
                              attrs: { width: "50%", height: "200px;" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectPaymentType(2)
                                },
                              },
                            },
                            [
                              _c(
                                VCardTitle,
                                {
                                  staticStyle: { "justify-content": "center" },
                                },
                                [_vm._v("카드")]
                              ),
                              _c(
                                VCardText,
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("Icon", {
                                    attrs: {
                                      icon: "bi:credit-card",
                                      width: "70",
                                      height: "70",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.choice == 1
        ? _c(
            "div",
            { staticStyle: { height: "100%" } },
            [
              _c(
                VRow,
                {
                  staticStyle: {
                    "justify-content": "space-between",
                    "margin-left": "0px",
                    "margin-right": "0px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "main-title",
                      staticStyle: {
                        "margin-left": "5%",
                        "padding-top": "20px",
                        "margin-bottom": "-20px",
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.getMainTitle) +
                          "\n            "
                      ),
                    ]
                  ),
                  _c(
                    VBtn,
                    {
                      staticStyle: {
                        "margin-top": "15px",
                        "margin-right": "5px",
                      },
                      attrs: { "x-small": "", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.back()
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v("mdi-keyboard-backspace"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticStyle: {
                    "margin-left": "3%",
                    "margin-right": "3%",
                    "margin-top": "-40px",
                  },
                },
                [
                  _c(
                    VCol,
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { "padding-left": "7px" } },
                        [
                          _c(
                            VRow,
                            { staticStyle: { "margin-top": "15px" } },
                            [
                              _c("div", { staticClass: "content-title" }, [
                                _vm._v("이 름 :"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "content-text",
                                  staticStyle: { margin: "3px 0 0 5px" },
                                },
                                [_vm._v(" " + _vm._s(_vm.getUserName))]
                              ),
                            ]
                          ),
                          _c(
                            VRow,
                            { staticStyle: { "margin-bottom": "5px" } },
                            [
                              _c("div", { staticClass: "content-title" }, [
                                _vm._v("이메일 :"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "content-text",
                                  staticStyle: { margin: "3px 0 0 5px" },
                                },
                                [_vm._v(" " + _vm._s(_vm.getUserEmail))]
                              ),
                            ]
                          ),
                          _c(VDivider, {
                            staticStyle: {
                              margin: "5px 0 0 -10px",
                              "padding-bottom": "5px",
                            },
                          }),
                          _c(
                            VRow,
                            { staticStyle: { "margin-top": "0px" } },
                            [
                              _c("div", { staticClass: "content-title" }, [
                                _vm._v("결제 상품 :"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "content-text",
                                  staticStyle: { margin: "3px 0 0 5px" },
                                },
                                [_vm._v(_vm._s(_vm.getTitle))]
                              ),
                            ]
                          ),
                          _c(VRow, [
                            _c("div", { staticClass: "content-title" }, [
                              _vm._v("사용 기간 :"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content-text",
                                staticStyle: { margin: "3px 0 0 5px" },
                              },
                              [_vm._v(_vm._s(_vm.getPeriodDate))]
                            ),
                          ]),
                          _c(VRow, [
                            _c("div", { staticClass: "content-title" }, [
                              _vm._v("결제 금액 :"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content-text",
                                staticStyle: {
                                  "font-weight": "700",
                                  margin: "3px 0 0 5px",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.getPaidAmount) +
                                    " " +
                                    _vm._s(_vm.isPgPayment ? "원" : "Coin") +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]),
                          _c(VRow, [
                            _c("div", { staticClass: "content-title" }, [
                              _vm._v("남은 코인 :"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content-text",
                                staticStyle: { margin: "3px 0 0 5px" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.getCalculateAmount) +
                                    "\n                            Coin\n                        "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        {
                          staticStyle: {
                            "justify-content": "space-between",
                            "margin-top": "20px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "10px",
                                "align-self": "center",
                                margin: "20px 0 0 3px",
                              },
                            },
                            [_vm._v("'환불 불가' 상품")]
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                VBtn,
                                {
                                  staticStyle: { background: "#175fc7" },
                                  attrs: {
                                    disabled: _vm.paying,
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submit()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            코인 사용\n                        "
                                  ),
                                ]
                              ),
                              _c(
                                VBtn,
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.close(true)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("word.close")) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.choice == 2
        ? _c(
            "div",
            { staticStyle: { height: "100%" } },
            [
              _c(
                VBtn,
                {
                  staticStyle: { float: "right", right: "-10px" },
                  attrs: { small: "", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [
                  _c(VIcon, { attrs: { small: "" } }, [
                    _vm._v("mdi-keyboard-backspace"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "main-title",
                  staticStyle: { "margin-left": "38%", "padding-top": "10px" },
                },
                [_vm._v("카드 결제")]
              ),
              _c(
                VCard,
                {
                  staticClass: "choice-card",
                  staticStyle: {
                    width: "100%",
                    "margin-bottom": "10px",
                    display: "block",
                    "text-aling": "center",
                  },
                  attrs: { outlined: "", flat: "" },
                },
                [
                  _c(
                    VCardText,
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCard,
                            {
                              attrs: { width: "50%", height: "200px;" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectPaymentType(3)
                                },
                              },
                            },
                            [
                              _c(
                                VCardTitle,
                                {
                                  staticStyle: { "justify-content": "center" },
                                },
                                [_vm._v("단건")]
                              ),
                              _c(
                                VCardText,
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("Icon", {
                                    attrs: {
                                      icon: "fluent:book-24-filled",
                                      width: "70",
                                      height: "70",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            VCard,
                            {
                              attrs: { width: "50%", height: "200px;" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectPaymentType(4)
                                },
                              },
                            },
                            [
                              _c(
                                VCardTitle,
                                {
                                  staticStyle: { "justify-content": "center" },
                                },
                                [_vm._v("구독")]
                              ),
                              _c(
                                VCardText,
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("Icon", {
                                    attrs: {
                                      icon: "icomoon-free:books",
                                      width: "70",
                                      height: "70",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.choice == 3
        ? _c(
            "div",
            { staticStyle: { height: "100%" } },
            [
              _c(
                VRow,
                {
                  staticStyle: {
                    "justify-content": "space-between",
                    "margin-left": "0px",
                    "margin-right": "0px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "main-title",
                      staticStyle: {
                        "margin-left": "5%",
                        "padding-top": "20px",
                        "margin-bottom": "-20px",
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.getMainTitle) +
                          "\n            "
                      ),
                    ]
                  ),
                  _c(
                    VBtn,
                    {
                      staticStyle: {
                        "margin-top": "15px",
                        "margin-right": "5px",
                      },
                      attrs: { "x-small": "", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.back()
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v("mdi-keyboard-backspace"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticStyle: {
                    "margin-left": "3%",
                    "margin-right": "3%",
                    "margin-top": "-40px",
                  },
                },
                [
                  _c(VCol, { staticStyle: { "margin-top": "10px" } }, [
                    _c(
                      "div",
                      { staticStyle: { "padding-left": "7px" } },
                      [
                        _c(VRow, { staticStyle: { "margin-top": "15px" } }, [
                          _c("div", { staticClass: "content-title" }, [
                            _vm._v("이 름 :"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "content-text",
                              staticStyle: { margin: "3px 0 0 5px" },
                            },
                            [_vm._v(" " + _vm._s(_vm.getUserName))]
                          ),
                        ]),
                        _c(
                          VRow,
                          { staticStyle: { "margin-bottom": "5px" } },
                          [
                            _c("div", { staticClass: "content-title" }, [
                              _vm._v("이메일 :"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content-text",
                                staticStyle: { margin: "3px 0 0 5px" },
                              },
                              [_vm._v(" " + _vm._s(_vm.getUserEmail))]
                            ),
                          ]
                        ),
                        _c(VDivider, {
                          staticStyle: {
                            margin: "5px 0 0 -10px",
                            "padding-bottom": "5px",
                          },
                        }),
                        _c(VRow, { staticStyle: { "margin-top": "0px" } }, [
                          _c("div", { staticClass: "content-title" }, [
                            _vm._v("결제 상품 :"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "content-text",
                              staticStyle: { margin: "3px 0 0 5px" },
                            },
                            [_vm._v(_vm._s(_vm.getTitle))]
                          ),
                        ]),
                        _c(VRow, [
                          _c("div", { staticClass: "content-title" }, [
                            _vm._v("사용 기간 :"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "content-text",
                              staticStyle: { margin: "3px 0 0 5px" },
                            },
                            [_vm._v(_vm._s(_vm.getPeriodDate))]
                          ),
                        ]),
                        _c(VRow, [
                          _c("div", { staticClass: "content-title" }, [
                            _vm._v("결제 금액 :"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "content-text",
                              staticStyle: {
                                "font-weight": "700",
                                margin: "3px 0 0 5px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.getPaidAmount) + " 원")]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticStyle: { margin: "0 0 -60px -12px" } },
                          [
                            _c(VCheckbox, {
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "gs-content-detail" },
                                        [
                                          _vm._v(
                                            "\n                                    (필수) 구매조건 및 개인정보취급방침 동의\n                                    "
                                          ),
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "a",
                                                        _vm._g(
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.moveProvision()
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                                                (보기)\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        열기\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.agreePayment,
                                callback: function ($$v) {
                                  _vm.agreePayment = $$v
                                },
                                expression: "agreePayment",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VRow,
                          {
                            staticStyle: {
                              "justify-content": "space-between",
                              "margin-top": "20px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "10px",
                                  "align-self": "center",
                                  margin: "20px 0 0 3px",
                                },
                              },
                              [_vm._v("'환불 불가' 상품")]
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  VBtn,
                                  {
                                    staticStyle: { background: "#175fc7" },
                                    attrs: {
                                      disabled: _vm.paying || !_vm.agreePayment,
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submit()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                결제\n                            "
                                    ),
                                  ]
                                ),
                                _c(
                                  VBtn,
                                  {
                                    attrs: { text: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.close(true)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("word.close")) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm.choice == 4
        ? _c(
            "div",
            { staticStyle: { height: "100%" } },
            [
              _c(
                VRow,
                {
                  staticStyle: {
                    "justify-content": "space-between",
                    "margin-left": "0px",
                    "margin-right": "0px",
                  },
                },
                [
                  _c("div", {
                    staticClass: "main-title",
                    staticStyle: {
                      "margin-left": "5%",
                      "padding-top": "20px",
                      "margin-bottom": "-20px",
                    },
                  }),
                  _c(
                    VBtn,
                    {
                      staticStyle: {
                        "margin-right": "5px",
                        "margin-top": "15px",
                      },
                      attrs: { "x-small": "", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.back()
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v("mdi-keyboard-backspace"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticStyle: {
                    "margin-left": "3%",
                    "margin-right": "3%",
                    "margin-top": "3%",
                    height: "100%",
                  },
                },
                [
                  _c("SubscriptionItemTemplate", {
                    attrs: {
                      userSavedAmount: _vm.getUserSavedAmount,
                      userEmail: _vm.getUserEmail,
                      userName: _vm.getUserName,
                    },
                    on: { close: _vm.close },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }