var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "canvas-panel" },
    [
      _c(
        "md-layout",
        [
          _c(
            "md-layout",
            { attrs: { "md-flex": "20" } },
            [
              _vm.id && _vm.id.indexOf("@") > -1
                ? _c(
                    "md-layout",
                    [
                      !_vm.isProduction
                        ? _c(
                            "md-button",
                            {
                              staticClass: "md-raised md-accent",
                              on: { click: _vm.markProduction },
                            },
                            [
                              _vm._v(
                                "Mark Production (rev\n          " +
                                  _vm._s(
                                    _vm.id.substring(_vm.id.indexOf("@") + 1)
                                  ) +
                                  ")\n        "
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            [
                              _c("md-button", { staticClass: "md-primary" }, [
                                _vm._v(
                                  "this is production (rev. " +
                                    _vm._s(
                                      _vm.id.substring(_vm.id.indexOf("@") + 1)
                                    ) +
                                    ")"
                                ),
                              ]),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.versions
                ? _c(
                    "md-layout",
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-primary",
                          on: { click: _vm.loadVersions },
                        },
                        [_vm._v("latest version")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.monitor
                ? _c(
                    "md-list",
                    { staticClass: "tree-list" },
                    [_c("bpmn-tree-list", { attrs: { trees: _vm.trees } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "md-layout",
            {
              nativeOn: {
                contextmenu: function ($event) {
                  return _vm.openMenu.apply(null, arguments)
                },
                mousedown: function ($event) {
                  return _vm.closeMenu.apply(null, arguments)
                },
              },
            },
            [
              _vm.loaded == false
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        left: "48%",
                        top: "37%",
                        "z-index": "99999",
                      },
                    },
                    [
                      _c("md-spinner", {
                        staticStyle: { "z-index": "99999" },
                        attrs: { "md-size": 90, "md-indeterminate": "" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.definition
                ? _c("bpmn-vue", {
                    ref: "bpmn-vue",
                    style: _vm.cursorStyle,
                    attrs: {
                      definition: _vm.definition,
                      monitor: _vm.monitor,
                      backend: _vm.backend,
                      dragPageMovable: _vm.dragPageMovable,
                      loaded: _vm.loaded,
                    },
                    on: {
                      "update:definition": function ($event) {
                        _vm.definition = $event
                      },
                      bpmnReady: _vm.bindEvents,
                      "update:loaded": function ($event) {
                        _vm.loaded = $event
                      },
                    },
                  })
                : _vm._e(),
              !_vm.monitor
                ? _c(
                    "md-card",
                    { staticClass: "tools", staticStyle: { top: "100px" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "bpmn-icon-hand-tool hands",
                          style: _vm.handsStyle,
                          attrs: { _width: "30", _height: "30" },
                          on: { click: _vm.changeMultiple },
                        },
                        [
                          _c(
                            "md-tooltip",
                            { attrs: { "md-direction": "right" } },
                            [_vm._v("Drag On/Off")]
                          ),
                        ],
                        1
                      ),
                      _vm._l(_vm.dragItems, function (item) {
                        return _c(
                          "span",
                          {
                            staticClass: "icons draggable",
                            class: item.icon,
                            attrs: {
                              _component: item.component,
                              _width: item.width,
                              _height: item.height,
                            },
                          },
                          [
                            _c(
                              "md-tooltip",
                              { attrs: { "md-direction": "right" } },
                              [_vm._v(_vm._s(item.label))]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              !_vm.monitor
                ? _c(
                    "md-card",
                    { staticClass: "tools", staticStyle: { top: "427px" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "icons fa fa-undo",
                          staticStyle: { "margin-left": "7px" },
                          on: { click: _vm.undo },
                        },
                        [
                          _c(
                            "md-tooltip",
                            { attrs: { "md-direction": "right" } },
                            [_vm._v("Undo")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "icons fa fa-repeat",
                          staticStyle: { "margin-left": "7px" },
                          on: { click: _vm.redo },
                        },
                        [
                          _c(
                            "md-tooltip",
                            { attrs: { "md-direction": "right" } },
                            [_vm._v("Redo")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "md-layout",
                [
                  !_vm.monitor
                    ? _c(
                        "md-layout",
                        [
                          _c(
                            "md-input-container",
                            [
                              _c("label", [_vm._v("Process Name")]),
                              _c("md-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.definitionName,
                                  callback: function ($$v) {
                                    _vm.definitionName = $$v
                                  },
                                  expression: "definitionName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.versions
                    ? _c(
                        "md-layout",
                        [
                          _c(
                            "md-input-container",
                            [
                              _c("label", [_vm._v("Revision")]),
                              _c(
                                "md-select",
                                {
                                  on: { change: _vm.changeVersion },
                                  model: {
                                    value: _vm.selectedVersion,
                                    callback: function ($$v) {
                                      _vm.selectedVersion = $$v
                                    },
                                    expression: "selectedVersion",
                                  },
                                },
                                _vm._l(
                                  _vm.versions.slice().reverse(),
                                  function (version) {
                                    return _c(
                                      "md-option",
                                      { attrs: { value: version } },
                                      [
                                        _vm._v(
                                          "rev. " +
                                            _vm._s(version.ver) +
                                            "\n                "
                                        ),
                                        _vm.productionVersionId ==
                                        version.versionId
                                          ? _c("md-chip", [
                                              _vm._v("production"),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.monitor
                    ? _c(
                        "md-layout",
                        [
                          _c(
                            "md-button",
                            {
                              attrs: {
                                c: "",
                                lass: "md-primary",
                                id: "processVariables",
                              },
                              on: { click: _vm.openDefinitionSettings },
                            },
                            [
                              _c("md-icon", [_vm._v("settings")]),
                              _vm._v("\n            Settings\n          "),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.monitor
                    ? _c(
                        "md-layout",
                        [
                          _c(
                            "md-button",
                            {
                              attrs: {
                                c: "",
                                lass: "md-primary",
                                id: "processVariables",
                              },
                              on: { click: _vm.openProcessVariables },
                            },
                            [
                              _c("md-icon", [_vm._v("sort_by_alpha")]),
                              _vm._v("\n            Vars\n          "),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.monitor && _vm.definition
                    ? _c(
                        "md-layout",
                        { staticStyle: { "max-width": "200px" } },
                        [
                          _c(
                            "md-input-container",
                            [
                              _c("label", [_vm._v("Language")]),
                              _c(
                                "md-select",
                                {
                                  on: { change: _vm.changeLocale },
                                  model: {
                                    value: _vm.definition._selectedLocale,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.definition,
                                        "_selectedLocale",
                                        $$v
                                      )
                                    },
                                    expression: "definition._selectedLocale",
                                  },
                                },
                                [
                                  _c("md-option", { attrs: { value: "ko" } }, [
                                    _vm._v("Korean"),
                                  ]),
                                  _c("md-option", { attrs: { value: "en" } }, [
                                    _vm._v("English"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.monitor
                    ? _c(
                        "md-layout",
                        { staticStyle: { "margin-left": "30px" } },
                        [
                          !_vm.monitor
                            ? _c(
                                "md-button",
                                {
                                  staticClass: "md-fab md-primary md-mini",
                                  on: { click: _vm.initiateProcess },
                                },
                                [_c("md-icon", [_vm._v("play_arrow")])],
                                1
                              )
                            : _vm._e(),
                          !_vm.monitor
                            ? _c(
                                "md-button",
                                {
                                  staticClass: "md-fab md-warn md-mini",
                                  on: { click: _vm.save },
                                },
                                [
                                  _vm.id && _vm.id.indexOf("@") == -1
                                    ? _c("md-icon", [_vm._v("save")])
                                    : _c("md-icon", [_vm._v("history")]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.monitor
                    ? _c(
                        "md-layout",
                        [
                          _c(
                            "md-input-container",
                            [
                              _c("label", [_vm._v("Instance Name")]),
                              _c("md-input", {
                                attrs: { type: "text", readonly: "" },
                                model: {
                                  value: _vm.instanceName,
                                  callback: function ($$v) {
                                    _vm.instanceName = $$v
                                  },
                                  expression: "instanceName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.monitor
                    ? _c(
                        "md-layout",
                        [
                          _c(
                            "md-button",
                            {
                              attrs: { id: "instanceVariables" },
                              on: { click: _vm.openInstanceVariables },
                            },
                            [_vm._v("Variables")]
                          ),
                          _vm.definition
                            ? _c("bpmn-instance-variables", {
                                ref: "instanceVariables",
                                attrs: {
                                  id: _vm.instanceId,
                                  definition: _vm.definition,
                                },
                              })
                            : _vm._e(),
                          _c(
                            "md-button",
                            {
                              attrs: { id: "userPicker" },
                              on: { click: _vm.openUserPicker },
                            },
                            [_vm._v("Role Mappings")]
                          ),
                          _vm.definition
                            ? _c("user-picker", {
                                ref: "userPicker",
                                staticStyle: { "min-width": "70%" },
                                attrs: {
                                  id: _vm.instanceId,
                                  roles: _vm.definition.roles,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
      _c("modeler-image-generator", { ref: "modeler-image-generator" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "custom-menu" }, [
      _c("li", { attrs: { "data-action": "backToHere" } }, [
        _vm._v("Back To Here"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }