import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VContainer,
        { staticStyle: { "max-width": "1500px" } },
        [
          _vm._t("body", function () {
            return [
              _c(
                "div",
                [
                  _c(
                    "div",
                    {
                      class: _vm.isForeign
                        ? "isForeign-main-nav-tabs-box"
                        : "main-nav-tabs-box",
                    },
                    [
                      _c(
                        VTabs,
                        {
                          staticClass: "main-nav-tabs",
                          attrs: {
                            "background-color": "transparent",
                            "show-arrows": "",
                            color: "none",
                          },
                        },
                        [
                          _c(
                            VTab,
                            {
                              staticClass: "main-nav-tab main-nav-tab-home",
                              attrs: { to: "/" },
                            },
                            [
                              _c(
                                VIcon,
                                { staticStyle: { "margin-top": "10px" } },
                                [_vm._v("mdi-home")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            VTab,
                            {
                              staticClass: "main-nav-tab",
                              on: {
                                click: function ($event) {
                                  return _vm.wikiOpen("introduction")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("mainNav.introduction")) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _c(
                            VTab,
                            {
                              staticClass: "main-nav-tab",
                              on: {
                                click: function ($event) {
                                  return _vm.wikiOpen("company")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("mainNav.company")) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _c(
                            VTab,
                            {
                              staticClass: "main-nav-tab",
                              on: {
                                click: function ($event) {
                                  return _vm.wikiOpen("pricing")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("mainNav.pricing")) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _c(
                            VTab,
                            {
                              staticClass: "main-nav-tab",
                              on: {
                                click: function ($event) {
                                  return _vm.wikiOpen("partnership")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("mainNav.partnership")) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _c(
                            VTab,
                            {
                              staticClass: "main-nav-tab",
                              on: { click: _vm.nationLearnNavDialog },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("mainNav.learn")) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _c(
                            VIcon,
                            {
                              staticClass: "main-nav-tab",
                              staticStyle: { "margin-left": "10px" },
                              on: {
                                click: function ($event) {
                                  _vm.searchOpen = !_vm.searchOpen
                                },
                              },
                            },
                            [_vm._v("mdi-magnify\n                        ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(VHover, {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ hover }) {
                          return [
                            _c(
                              VListGroup,
                              {
                                staticClass: "nav-storage-list",
                                class: _vm.isForeign
                                  ? "isForeign-nav-storage-list"
                                  : "isForeign-not-nav-storage-list",
                                attrs: { value: hover, "append-icon": null },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function () {
                                        return [
                                          _c(
                                            VListItemTitle,
                                            {
                                              staticStyle: {
                                                margin: "5px 0px 0px 10px",
                                                "font-weight": "700",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c("Icon", {
                                                    staticStyle: {
                                                      "margin-right": "5px",
                                                    },
                                                    attrs: {
                                                      icon: "material-symbols:home-storage",
                                                      width: "26",
                                                      height: "26",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "cp-storage",
                                                      class: _vm.isForeign
                                                        ? "isForeign-storage-main-list-text"
                                                        : "isNotForeign-storage-main-list-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "mainNav.Storage"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  VCard,
                                  {
                                    staticClass: "mx-auto pt-2 pb-2",
                                    attrs: { "max-width": "300" },
                                  },
                                  _vm._l(
                                    _vm.filterTabLists,
                                    function (tabObj, tabIndex) {
                                      return tabObj.id !== "home" && tabObj.show
                                        ? _c(
                                            VListItem,
                                            {
                                              key: tabObj.id,
                                              attrs: { link: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.tabId = tabObj.id
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                {
                                                  staticStyle: {
                                                    "margin-top": "2px",
                                                  },
                                                },
                                                [_vm._v(_vm._s(tabObj.display))]
                                              ),
                                              tabIndex > 0 &&
                                              tabObj.totalCount != null
                                                ? _c(
                                                    VAvatar,
                                                    {
                                                      staticStyle: {
                                                        "font-size": "10px",
                                                      },
                                                      attrs: {
                                                        color:
                                                          "green lighten-5",
                                                        size: "30",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            tabObj.totalCount ==
                                                              null
                                                              ? "..."
                                                              : tabObj.totalCount ==
                                                                0
                                                              ? "0"
                                                              : tabObj.totalCount
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    VDialog,
                    {
                      attrs: { "max-width": "90%" },
                      model: {
                        value: _vm.learnNavDialog,
                        callback: function ($$v) {
                          _vm.learnNavDialog = $$v
                        },
                        expression: "learnNavDialog",
                      },
                    },
                    [
                      _c(
                        VCard,
                        {
                          staticStyle: {
                            padding: "10px",
                            height: "85vh",
                            overflow: "auto",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "24px",
                                "font-weight": "700",
                                "text-align": "center",
                                margin: "5px 0px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("mainNav.learn")))]
                          ),
                          _c(
                            VRow,
                            { staticStyle: { margin: "0px" } },
                            [
                              _vm._l(_vm.navSubCards, function (item, index) {
                                return _c(
                                  VCol,
                                  {
                                    key: index,
                                    attrs: {
                                      lg: "4",
                                      md: "4",
                                      sm: "6",
                                      xs: "12",
                                    },
                                  },
                                  [
                                    _c(
                                      VCard,
                                      {
                                        staticStyle: { height: "100%" },
                                        attrs: { outlined: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.wikiOpen("business")
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          VCardTitle,
                                          { staticClass: "justify-center" },
                                          [_vm._v(_vm._s(_vm.$t(item.title)))]
                                        ),
                                        _c(VImg, {
                                          staticStyle: {
                                            cursor: "pointer",
                                            height: "200px",
                                          },
                                          attrs: { src: item.image },
                                        }),
                                        _c(
                                          VCardSubtitle,
                                          {
                                            staticStyle: {
                                              "margin-bottom": "20px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t(item.subtitle))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "4",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticStyle: { height: "100%" },
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.navigateTo("/courses")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        VCardTitle,
                                        { staticClass: "justify-center" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("mainNavSubCard.Academy")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(VImg, {
                                        staticStyle: {
                                          cursor: "pointer",
                                          height: "200px",
                                        },
                                        attrs: {
                                          src: "/static/image/main/mainSubLectures.png",
                                        },
                                      }),
                                      _c(
                                        VCardSubtitle,
                                        {
                                          staticStyle: {
                                            "margin-bottom": "20px",
                                          },
                                        },
                                        [_vm._v(_vm._s())]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    lg: "4",
                                    md: "4",
                                    sm: "6",
                                    xs: "12",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticStyle: { height: "100%" },
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.navigateTo(
                                            "https://www.msaschool.io/operation/introduction/curriculum/"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        VCardTitle,
                                        { staticClass: "justify-center" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("mainNavSubCard.training")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(VImg, {
                                        staticStyle: {
                                          cursor: "pointer",
                                          height: "200px",
                                        },
                                        attrs: {
                                          src: "/static/image/main/mainSubTraining.png",
                                        },
                                      }),
                                      _c(
                                        VCardSubtitle,
                                        {
                                          staticStyle: {
                                            "margin-bottom": "20px",
                                          },
                                        },
                                        [_vm._v(_vm._s())]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.searchOpen
                    ? _c(
                        VAlert,
                        {
                          staticClass: "ma-0 pa-2",
                          staticStyle: {
                            position: "fixed",
                            top: "50px",
                            "z-index": "2",
                            width: "40%",
                            left: "50%",
                            transform: "translate(-50%, 0%)",
                          },
                          attrs: { elevation: "2" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                VRow,
                                { staticClass: "ma-0 pa-0" },
                                [
                                  _c(VSpacer),
                                  _c(
                                    VIcon,
                                    {
                                      attrs: { size: 16 },
                                      on: {
                                        click: function ($event) {
                                          return _vm.searchClose(true)
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VRow,
                                {
                                  staticClass: "ma-0 pa-0",
                                  staticStyle: { "align-items": "baseline" },
                                },
                                [
                                  _c(
                                    VMenu,
                                    {
                                      attrs: { "offset-y": "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  VChip,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticStyle: {
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          color:
                                                            _vm.selectedMode
                                                              .color,
                                                          outlined: "",
                                                        },
                                                      },
                                                      "v-chip",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.selectedMode
                                                            .display
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2954027779
                                      ),
                                    },
                                    [
                                      _c(
                                        VList,
                                        _vm._l(
                                          _vm.listMode,
                                          function (item, index) {
                                            return _c(
                                              VListItem,
                                              {
                                                key: index,
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectMode(item)
                                                  },
                                                },
                                              },
                                              [
                                                _c(VListItemTitle, [
                                                  _vm._v(_vm._s(item.display)),
                                                ]),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(VTextField, {
                                    staticClass: "gs-main-search",
                                    attrs: {
                                      outlined: "",
                                      label: _vm.searchObj.name
                                        ? _vm.filterListsCount
                                        : "Search for name",
                                      autofocus: "",
                                      "hide-details": "",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.searchObj.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchObj, "name", $$v)
                                      },
                                      expression: "searchObj.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    VTabsItems,
                    {
                      model: {
                        value: _vm.selectedTabIndex,
                        callback: function ($$v) {
                          _vm.selectedTabIndex = $$v
                        },
                        expression: "selectedTabIndex",
                      },
                    },
                    [
                      _vm.selectedTabIndex == -1
                        ? _c(
                            VTabItem,
                            { attrs: { value: _vm.selectedTabIndex } },
                            [
                              _c(
                                VRow,
                                { staticStyle: { "margin-top": "0px" } },
                                [
                                  _vm.showLoading ||
                                  (_vm.filteredList == undefined &&
                                    typeof _vm.filteredList == "undefined")
                                    ? _c(
                                        VCol,
                                        { staticStyle: { height: "100%" } },
                                        [
                                          _c(
                                            VRow,
                                            _vm._l(9, function (idx) {
                                              return _c(
                                                VCol,
                                                {
                                                  attrs: { cols: "6", md: "4" },
                                                },
                                                [
                                                  _c(
                                                    VCard,
                                                    {
                                                      staticClass: "mx-auto",
                                                      staticStyle: {
                                                        width: "500px",
                                                        height: "400px",
                                                        "justify-content":
                                                          "center",
                                                      },
                                                      attrs: {
                                                        outlined: "",
                                                        align: "center",
                                                      },
                                                    },
                                                    [
                                                      _c(VSkeletonLoader, {
                                                        ref: "skeleton",
                                                        refInFor: true,
                                                        staticClass: "mx-auto",
                                                        attrs: { type: "card" },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.filteredList == null &&
                                      typeof _vm.filteredList == "object"
                                    ? _c(
                                        VCol,
                                        { staticStyle: { height: "590px" } },
                                        [
                                          _vm._v(
                                            "\n                                No Items\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._l(
                                        _vm.filteredList,
                                        function (project, index) {
                                          return _c(
                                            VCol,
                                            {
                                              key: project.projectId,
                                              attrs: {
                                                xl: "4",
                                                lg: "4",
                                                md: "6",
                                                sm: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "EventStormingListCard",
                                                {
                                                  attrs: {
                                                    information: project,
                                                  },
                                                  on: {
                                                    delete:
                                                      _vm.deleteProjectItem,
                                                    openAutoModelingDialog:
                                                      _vm.openAutoModelingDialog,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "action",
                                                        fn: function (project) {
                                                          return [
                                                            _vm._t(
                                                              "action",
                                                              null,
                                                              {
                                                                project:
                                                                  project,
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "chips" },
                                                    [_vm._t("chips")],
                                                    2
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm.selectedTabIndex == 0
                        ? _c(
                            VTabItem,
                            { attrs: { value: _vm.selectedTabIndex } },
                            [
                              _c(
                                "div",
                                { staticClass: "gs-main-page-top-box" },
                                [
                                  _c(
                                    "transition",
                                    { attrs: { name: "fade", mode: "out-in" } },
                                    _vm._l(_vm.mainTexts, function (item) {
                                      return _vm.currentTextId === item.id
                                        ? _c(
                                            "div",
                                            { key: item.id },
                                            [
                                              _c(
                                                VRow,
                                                {
                                                  attrs: {
                                                    justify: "start",
                                                    align: "center",
                                                  },
                                                },
                                                [
                                                  _c(VCol, {
                                                    attrs: { cols: "2" },
                                                  }),
                                                  _c(
                                                    VCol,
                                                    {
                                                      staticClass:
                                                        "gs-main-page-text-box",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "justify-content":
                                                              "flex-start",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "text-align":
                                                                  "left",
                                                                "padding-right":
                                                                  "10px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "gs-main-page-top-title",
                                                                  staticStyle: {
                                                                    "white-space":
                                                                      "pre-wrap",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        item.title
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "gs-main-page-sub-title",
                                                                },
                                                                _vm._l(
                                                                  item.content,
                                                                  function (
                                                                    content
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: content,
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              content
                                                                            )
                                                                          )
                                                                        ),
                                                                        _c(
                                                                          "br"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticStyle: {
                                                                    "text-decoration":
                                                                      "none",
                                                                    color:
                                                                      "inherit",
                                                                  },
                                                                  attrs: {
                                                                    href: _vm.isForeign
                                                                      ? item.goToUrlEn
                                                                      : item.goToUrl,
                                                                    target:
                                                                      "_blank",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    VBtn,
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-top":
                                                                            "5px",
                                                                          "font-size":
                                                                            "18px",
                                                                          "font-weight":
                                                                            "700",
                                                                          padding:
                                                                            "0px",
                                                                        },
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        text: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "main.goToUrlText"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    VCol,
                                                    {
                                                      staticClass:
                                                        "gs-main-page-img-box",
                                                      attrs: { cols: "4" },
                                                    },
                                                    [
                                                      _c(VImg, {
                                                        attrs: {
                                                          src: item.imageUrl,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(VCol, {
                                                    attrs: { cols: "2" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "gs-main-page-top-box-mobile" },
                                [
                                  _c(
                                    "transition",
                                    { attrs: { name: "fade", mode: "out-in" } },
                                    _vm._l(_vm.mainTexts, function (item) {
                                      return _vm.currentTextId === item.id
                                        ? _c(
                                            "div",
                                            { key: item.id },
                                            [
                                              _c(
                                                VRow,
                                                [
                                                  _c(VCol, {
                                                    attrs: { cols: "3.5" },
                                                  }),
                                                  _c(
                                                    VCol,
                                                    {
                                                      staticClass:
                                                        "gs-main-page-img-box-mobile",
                                                      attrs: { cols: "5" },
                                                    },
                                                    [
                                                      _c(VImg, {
                                                        attrs: {
                                                          src: item.imageUrl,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(VCol, {
                                                    attrs: { cols: "3.5" },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gs-main-page-top-title-mobile",
                                                      staticStyle: {
                                                        "white-space":
                                                          "pre-wrap",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(item.title)
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gs-main-page-sub-title-mobile",
                                                    },
                                                    _vm._l(
                                                      item.content,
                                                      function (content) {
                                                        return _c(
                                                          "div",
                                                          { key: content },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(content)
                                                              )
                                                            ),
                                                            _c("br"),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticStyle: {
                                                        "text-decoration":
                                                          "none",
                                                        color: "inherit",
                                                      },
                                                      attrs: {
                                                        href: item.goToUrl,
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VBtn,
                                                        {
                                                          staticStyle: {
                                                            "margin-top":
                                                              "10px",
                                                          },
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "main.goToUrlText"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                              _c("AutoModelingDialog", {
                                attrs: {
                                  mode: "es",
                                  showDialog: false,
                                  showChat: true,
                                },
                              }),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "30px" } },
                                [
                                  _c(
                                    "carousel",
                                    {
                                      attrs: {
                                        perPageCustom: [
                                          [0, 1],
                                          [576, 2],
                                          [768, 3],
                                          [992, 4],
                                          [1200, 5],
                                        ],
                                      },
                                    },
                                    _vm._l(_vm.logos, function (logo, index) {
                                      return _c("slide", { key: index }, [
                                        _c("img", {
                                          attrs: {
                                            src: logo.url,
                                            alt: logo.alt,
                                          },
                                        }),
                                      ])
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : 0 < _vm.selectedTabIndex && _vm.selectedTabIndex < 4
                        ? _c(
                            VTabItem,
                            {
                              key: _vm.selectedTabIndex,
                              attrs: { value: _vm.selectedTabIndex },
                            },
                            [
                              _c(
                                VRow,
                                { staticStyle: { "margin-top": "0px" } },
                                [
                                  _vm.showLoading ||
                                  (_vm.filteredList == undefined &&
                                    typeof _vm.filteredList == "undefined")
                                    ? _c(
                                        VCol,
                                        { staticStyle: { height: "100%" } },
                                        [
                                          _c(
                                            VRow,
                                            _vm._l(9, function (idx) {
                                              return _c(
                                                VCol,
                                                {
                                                  attrs: { cols: "6", md: "4" },
                                                },
                                                [
                                                  _c(
                                                    VCard,
                                                    {
                                                      staticClass: "mx-auto",
                                                      staticStyle: {
                                                        width: "500px",
                                                        height: "400px",
                                                        "justify-content":
                                                          "center",
                                                      },
                                                      attrs: {
                                                        outlined: "",
                                                        align: "center",
                                                      },
                                                    },
                                                    [
                                                      _c(VSkeletonLoader, {
                                                        ref: "skeleton",
                                                        refInFor: true,
                                                        staticClass: "mx-auto",
                                                        attrs: { type: "card" },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.filteredList == null &&
                                      typeof _vm.filteredList == "object"
                                    ? _c(
                                        VCol,
                                        { staticStyle: { height: "590px" } },
                                        [
                                          _vm._v(
                                            "\n                                No Items\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._l(
                                        _vm.filteredList,
                                        function (project, index) {
                                          return _c(
                                            VCol,
                                            {
                                              key: project.projectId,
                                              attrs: {
                                                xl: "4",
                                                lg: "4",
                                                md: "6",
                                                sm: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "EventStormingListCard",
                                                {
                                                  attrs: {
                                                    information: project,
                                                  },
                                                  on: {
                                                    delete:
                                                      _vm.deleteProjectItem,
                                                    openAutoModelingDialog:
                                                      _vm.openAutoModelingDialog,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "action",
                                                        fn: function (project) {
                                                          return [
                                                            _vm._t(
                                                              "action",
                                                              null,
                                                              {
                                                                project:
                                                                  project,
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "chips" },
                                                    [_vm._t("chips")],
                                                    2
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm.selectedTabIndex == 4
                        ? _c(
                            VTabItem,
                            { attrs: { value: _vm.selectedTabIndex } },
                            [
                              _c(
                                VRow,
                                { staticStyle: { "margin-top": "0px" } },
                                [
                                  (_vm.showLoading &&
                                    _vm.filteredList &&
                                    _vm.filteredList.length < 10) ||
                                  (_vm.filteredList == undefined &&
                                    typeof _vm.filteredList == "undefined")
                                    ? _c(
                                        VCol,
                                        { staticStyle: { height: "100%" } },
                                        [
                                          _c(
                                            VRow,
                                            _vm._l(9, function (idx) {
                                              return _c(
                                                VCol,
                                                {
                                                  attrs: { cols: "6", md: "4" },
                                                },
                                                [
                                                  _c(
                                                    VCard,
                                                    {
                                                      staticClass: "mx-auto",
                                                      staticStyle: {
                                                        width: "500px",
                                                        height: "400px",
                                                        "justify-content":
                                                          "center",
                                                      },
                                                      attrs: {
                                                        outlined: "",
                                                        align: "center",
                                                      },
                                                    },
                                                    [
                                                      _c(VSkeletonLoader, {
                                                        ref: "skeleton",
                                                        refInFor: true,
                                                        staticClass: "mx-auto",
                                                        attrs: { type: "card" },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.filteredList == null &&
                                      typeof _vm.filteredList == "object"
                                    ? _c(
                                        VCol,
                                        { staticStyle: { height: "590px" } },
                                        [
                                          _vm._v(
                                            "\n                                No Items\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._l(
                                        _vm.filteredList,
                                        function (project, index) {
                                          return _c(
                                            VCol,
                                            {
                                              key: project.projectId,
                                              attrs: {
                                                xl: "4",
                                                lg: "4",
                                                md: "6",
                                                sm: "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "EventStormingListCard",
                                                {
                                                  attrs: {
                                                    information: project,
                                                  },
                                                  on: {
                                                    delete:
                                                      _vm.deleteProjectItem,
                                                    openAutoModelingDialog:
                                                      _vm.openAutoModelingDialog,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "action",
                                                        fn: function (project) {
                                                          return [
                                                            _vm._t(
                                                              "action",
                                                              null,
                                                              {
                                                                project:
                                                                  project,
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "chips" },
                                                    [_vm._t("chips")],
                                                    2
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.selectedTabIndex != 0
                    ? _c(
                        "div",
                        { staticStyle: { "text-align-last": "center" } },
                        [
                          _vm.showLoadingForMorePage
                            ? _c(
                                "div",
                                {
                                  staticClass: "ticontainer",
                                  staticStyle: {
                                    "margin-left": "50%",
                                    "margin-top": "30px",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "tiblock" }, [
                                    _c("div", { staticClass: "tidot" }),
                                    _c("div", { staticClass: "tidot" }),
                                    _c("div", { staticClass: "tidot" }),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          !_vm.showLoading && !_vm.showMoreButton
                            ? _c(
                                "div",
                                {
                                  staticStyle: { padding: "10px 0 10px 0" },
                                  attrs: { block: "", text: "" },
                                },
                                [_vm._v("마지막 페이지")]
                              )
                            : _vm.showLoading && _vm.showMoreButton
                            ? _c(
                                VRow,
                                _vm._l(9, function (idx) {
                                  return _c(
                                    VCol,
                                    { attrs: { cols: "6", md: "4" } },
                                    [
                                      _c(
                                        VCard,
                                        {
                                          staticClass: "mx-auto",
                                          staticStyle: {
                                            width: "500px",
                                            height: "400px",
                                            "justify-content": "center",
                                          },
                                          attrs: {
                                            outlined: "",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c(VSkeletonLoader, {
                                            ref: "skeleton",
                                            refInFor: true,
                                            staticClass: "mx-auto",
                                            attrs: { type: "card" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _vm.deleteItem
        ? _c(
            VDialog,
            {
              attrs: { persistent: "", "max-width": "470" },
              model: {
                value: _vm.deleteDialog,
                callback: function ($$v) {
                  _vm.deleteDialog = $$v
                },
                expression: "deleteDialog",
              },
            },
            [
              _c(
                VCard,
                [
                  _c(VCardTitle, { staticClass: "headline" }, [
                    _vm._v(_vm._s(_vm.$t("word.deleteNotification"))),
                  ]),
                  _c(VImg, { attrs: { src: _vm.deleteItem.img } }),
                  _c(VCardText, [
                    _vm._v(
                      "AuthorEmail: " +
                        _vm._s(
                          _vm.deleteItem.authorEmail
                            ? _vm.deleteItem.authorEmail.split("@")[0]
                            : "anyone"
                        ) +
                        "\n            "
                    ),
                  ]),
                  _c(VCardText, [
                    _vm._v(
                      "ProjectName: " +
                        _vm._s(
                          _vm.deleteItem.projectName
                            ? _vm.deleteItem.projectName
                            : "untitled"
                        )
                    ),
                  ]),
                  _c(VCardText, [
                    _vm._v(
                      "date: " +
                        _vm._s(
                          _vm.convertTimeStampToDate(
                            _vm.deleteItem.createdTimeStamp
                          )
                        )
                    ),
                  ]),
                  _c(VCardText, [
                    _vm._v(
                      "LastModifiedDate: " +
                        _vm._s(
                          _vm.convertTimeStampToDate(
                            _vm.deleteItem.lastModifiedTimeStamp
                          )
                        )
                    ),
                  ]),
                  _c(VCardText, [
                    _vm._v(
                      '## 알림 ## "공유"된 파일의 경우 공동작업자가 사본을 생성할 수 있습니다.'
                    ),
                  ]),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeDeleteDialog()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("word.close")))]
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "red darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteProject()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("word.delete")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        VFooter,
        {
          staticStyle: { "background-color": "transparent" },
          attrs: { padless: "" },
        },
        [
          _c("ProvisionIndication", {
            staticStyle: { margin: "0", padding: "0px", width: "100%" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }